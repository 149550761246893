import { Controller } from 'stimulus';

// This controller contains three main pieces of functionality that commonly occur on index pages:
// - Show/hide a loading spinner
// - Show/hide a content placeholder
// - Show/hide a new item form
//
// @data [String] url a URL to fetch the data from.
//
// @target content           area of the index page where dynamic items are displayed.
// @target disableOnLoading  elements to disable when the content items are changing.
// @target disableOnSubmit   buttons that should be disabled when a new item is submitted.
// @target formContainer     element containing the new item form.
// @target loadingRequester  element sending a server request to change the content items.
// @target loadingContent    element to show when the content items are changing.
// @target newItemButton     element that triggers the new item form to be shown.
// @target newItemFocusField field to focus on the new item form when it is shown.
// @target placeholder       element containing the placeholder to show when there is no content.
export default class extends Controller {
  static targets = [
    'content',
    'disableOnLoading',
    'disableOnSubmit',
    'formContainer',
    'loadingRequester',
    'loadingContent',
    'newItemButton',
    'newItemFocusField',
    'placeholder',
  ];

  // Show a loading spinner instead of the page content.
  _replaceContentWithLoadingContent() {
    this.cancelNewItem();

    if (this.hasLoadingContentTarget) {
      this._hidePlaceholder();
      this._hideContent();
      this._showLoadingContent();
    }
  }

  // Show the page content instead of the loading spinner.
  _replaceLoadingContentWithContent() {
    this._updatePlaceholder();
    this._hideLoadingContent();
  }

  // Listen to every element that could trigger a 'loading' event.
  // Whenever that element sends a request to the server, replace the
  // page content with a loading spinner and remove the new item form if it's visible.
  _addLoadingContentListeners() {
    this.loadingRequesterTargets.forEach((requester) => {
      requester.addEventListener(this.ajaxSendEvent, () => {
        this.disableOnLoadingTargets.forEach((element) => {
          element.dispatchEvent(new Event('disabled'));
          element.setAttribute('disabled', true);
        });

        this._replaceContentWithLoadingContent();
      });

      requester.addEventListener(this.ajaxCompleteEvent, () => {
        this.disableOnLoadingTargets.forEach((element) => {
          element.dispatchEvent(new Event('enabled'));
          element.removeAttribute('disabled');
        });

        this._replaceLoadingContentWithContent();
      });
    });
  }

  // Show the loading spinner target.
  _showLoadingContent() {
    this.loadingContentTargets.forEach((element) => {
      element.classList.remove(this.hiddenClass);
    });
  }

  // Hide the loading spinner target.
  _hideLoadingContent() {
    this.loadingContentTargets.forEach((element) => {
      element.classList.add(this.hiddenClass);
    });
  }

  // Display the placeholder element.
  _showPlaceholder() {
    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.classList.remove(this.hiddenClass);
    }
  }

  // Display the content element.
  _showContent() {
    if (this.hasContentTarget) {
      this.contentTarget.classList.remove(this.hiddenClass);
    }
  }

  // Hide the placeholder element.
  _hidePlaceholder() {
    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.classList.add(this.hiddenClass);
    }
  }

  // Hide the content element.
  _hideContent() {
    if (this.hasContentTarget) {
      this.contentTarget.classList.add(this.hiddenClass);
    }
  }

  // Determine whether or not the placeholder should be shown.
  _updatePlaceholder() {
    if (this.hasContentTarget) {
      if (this.contentTarget.children.length === 0) {
        this._showPlaceholder();
        this._hideContent();
      } else {
        this._hidePlaceholder();
        this._showContent();
      }
    }
  }

  // Watch the page content to determine when a placeholder should be shown.
  _addPlaceholderObservers() {
    const contentObserver = new MutationObserver(() => {
      this._updatePlaceholder();
    });

    if (this.hasContentTarget) {
      contentObserver.observe(this.contentTarget, { childList: true });
    }
  }

  // Given some form element, reset the form input fields and all nested Choices select inputs.
  //
  // @param [Element] formElement the form element to reset.
  _resetForm(formElement) {
    formElement.reset();
    formElement
      .querySelectorAll('[data-controller="choices--select"]')
      .forEach((element) => {
        element.dispatchEvent(new Event('reset'));
      });
  }

  // Hide the form target on the page.
  _hideFormTarget() {
    if (this.hasFormContainerTarget) {
      this.formContainerTarget.classList.add(this.hiddenClass);
    }
  }

  // Show the form target on the page.
  _showFormTarget() {
    if (this.hasFormContainerTarget) {
      this.formContainerTarget.classList.remove(this.hiddenClass);
    }
  }

  _enableNewItemButtonTarget() {
    if (this.hasNewItemButtonTarget) {
      this.newItemButtonTarget.removeAttribute('disabled');
    }
  }

  _disableNewItemButtonTarget() {
    if (this.hasNewItemButtonTarget) {
      this.newItemButtonTarget.setAttribute('disabled', true);
    }
  }

  // Watch the new item form so that we can correctly set the enabled/disabled attributes
  // of the buttons on the form.
  _addFormListeners(formElement) {
    formElement.addEventListener(this.ajaxSendEvent, () => {
      this.disableOnSubmitTargets.forEach((element) => {
        element.setAttribute('disabled', true);
        element.classList.add(this.loadingClass);
      });
    });

    formElement.addEventListener(this.ajaxSuccessEvent, () => {
      this._resetForm(formElement);
      this._hideFormTarget();
      this._enableNewItemButtonTarget();
    });

    formElement.addEventListener(this.ajaxCompleteEvent, () => {
      this.disableOnSubmitTargets.forEach((element) => {
        element.removeAttribute('disabled');
        element.classList.remove(this.loadingClass);
      });
    });
  }

  // Reset and hide the new item form.
  cancelNewItem() {
    if (this.hasFormContainerTarget) {
      this._resetForm(this.formContainerTarget.querySelector('form'));
      this._updatePlaceholder();
      this._hideFormTarget();
      this._enableNewItemButtonTarget();
    }
  }

  // Watch the new item button for clicks to ensure that we show the form
  // when requested.
  _addNewItemListeners() {
    if (this.hasNewItemButtonTarget) {
      this.newItemButtonTarget.addEventListener('click', () => {
        this._hidePlaceholder();
        this._disableNewItemButtonTarget();

        if (this.hasFormContainerTarget) {
          this._showFormTarget();

          if (this.hasNewItemFocusFieldTarget) {
            this.newItemFocusFieldTarget.focus();
          }

          this._addFormListeners(this.formContainerTarget.querySelector('form'));
        }
      });
    }
  }

  _setInitialContent() {
    if (this.data.has('url')) {
      this._replaceContentWithLoadingContent();

      Rails.ajax({
        url: this.data.get('url'),
        type: 'GET',
        success: () => {
          this._replaceLoadingContentWithContent();
        },
      });
    } else {
      this._updatePlaceholder();
    }
  }

  // Set up some initial controller-level variables that are commonly used.
  _setControllerVariables() {
    this.hiddenClass = 'is-hidden';
    this.loadingClass = 'is-loading';
    this.ajaxSendEvent = 'ajax:send';
    this.ajaxSuccessEvent = 'ajax:success';
    this.ajaxCompleteEvent = 'ajax:complete';
  }

  // When the controller is connected, set up all listeners, observers, and controller-level
  // variables.
  initialize() {
    this._setControllerVariables();
    this._setInitialContent();
    this._addLoadingContentListeners();
    this._addPlaceholderObservers();
    this._addNewItemListeners();
  }
}
