// Set a global variable for the flash message container to load user messages.
// Determine which event to listen to for animation endings.
document.addEventListener('turbolinks:load', () => {
  window.flashMessageContainer = document.getElementById('bss-flash-message-container');

  const sampleElement = document.createElement('div');
  const animations = {
    animation: 'animationend',
    OAnimation: 'oAnimationEnd',
    MozAnimation: 'mozAnimationEnd',
    WebkitAnimation: 'webkitAnimationEnd',
  };

  Object.keys(animations).forEach((key) => {
    if (sampleElement.style[key] !== undefined) {
      window.animationEndEvent = animations[key];
    }
  });
});
