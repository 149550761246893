import { Controller } from 'stimulus';
import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.min.css';

// This controller turns a text input field into a Choices.js tag input field.
export default class extends Controller {
  static targets = [];

  // Helper method to toggle the visibility of the Choices.js input dropdown.
  // For text inputs, this is just the 'Press enter to add ${value}' text.
  _setDropdownVisibility(visible) {
    if (visible) {
      document.querySelector('.choices__list--dropdown').style.display = '';
    } else {
      document.querySelector('.choices__list--dropdown').style.display = 'none';
    }
  }

  // Whenever this controller is connected, perform some setup to get the tag
  // field working with Choices.js.
  initialize() {
    // Initialize the Choices object with some stock options and styling.
    const choicesComponent = new Choices(this.element, {
      removeItemButton: true,
      duplicateItemsAllowed: false,
      addItemText: (value) => {
        const addItemText = `Press enter to add "<b>${value}</b>".`;
        return addItemText;
      },
      classNames: {
        list: 'tags has-margin-right-md',
        containerInner: 'choices__inner has-background-grey-lighter',
        input: ['choices__input', 'has-background-light', 'has-padding-sm'],
        button: 'delete is-small has-margin-left-sm',
        item: 'tag is-medium is-light',
      },
    });

    // Handle various events that are generated from Choices.js.
    this.element.addEventListener('showDropdown', () => {
      this._setDropdownVisibility(true);
    });

    this.element.addEventListener('hideDropdown', () => {
      this._setDropdownVisibility(false);
    });

    // Handle focus and blur events for the Choices.js input to make the UI a bit cleaner.
    if (choicesComponent.input) {
      choicesComponent.input.element.addEventListener('focus', () => {
        this._setDropdownVisibility(true);
      });

      choicesComponent.input.element.addEventListener('blur', () => {
        this._setDropdownVisibility(false);
      });
    }

    // Override the width setter so that we use Bulma styling instead.
    choicesComponent._setInputWidth = () => { };

    // Set up the input that is generated with Choices.js to have
    // our uppercase-field StimulusJS controller
    choicesComponent.input.element.dataset.controller = 'form--uppercase-input';
  }
}
