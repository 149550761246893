import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['dateRangeWrapper'];

  initialize() {
    const radioButton = this.element.querySelector('#new_time_sheet_form_time_span_custom');
    const customDateRange = radioButton.checked;

    if (customDateRange) {
      this.dateRangeWrapperTarget.classList.remove('is-hidden');
    }
  }

  toggleDateInputs(event) {
    const dateRange = event.target.value;

    if (dateRange === 'custom') {
      this.dateRangeWrapperTarget.classList.remove('is-hidden');
    } else {
      this.dateRangeWrapperTarget.classList.add('is-hidden');
    }
  }
}
