import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'element'];

  selectAll(e) {
    const { target } = e;
    window.t = target;
    if (target.checked) {
      this._selectAll(true);
    } else {
      this._selectAll();
    }
    this._checkCheckboxes();
  }

  _selectAll(checked = false) {
    this.checkboxTargets.forEach((element) => {
      element.checked = checked;
    });
  }

  // Determine toggle class from current class on element targets
  // is-invisible (fixed width hidden), is-hidden (hidden 0 width/height)
  _setToggleClass() {
    if (this.elementTargets[0].classList.contains('is-invisible')) {
      this.toggleClass = 'is-invisible';
    } else {
      this.toggleClass = 'is-hidden';
    }
  }

  // Iterate over all element targets and add the disabled attribute to
  // disable them.
  _hideElements() {
    this.elementTargets.forEach((element) => {
      element.classList.add(this.toggleClass);
    });
  }

  // Iterate over all element targets and remove the disabled attribute to
  // enable them.
  _showElements() {
    this.elementTargets.forEach((element) => {
      element.classList.remove(this.toggleClass);
    });
  }

  // Determine whether or not a given checkbox element has a value entered.
  //
  // @param [Element] checkboxElement the element to check the value of.
  //
  // @return [Boolean] true if the checkbox element has a value, otherwise false.
  _checkboxIsChecked(checkboxElement) {
    return checkboxElement.checked;
  }

  // Iterate through all checkboxes to determine if values are present for each one.
  // If all checkboxes have a value set, enable the element targets.
  // Otherwise, disable the element targets.
  _checkCheckboxes() {
    if (this.checkboxTargets.some(this._checkboxIsChecked) && this.checkboxTargets.length > 0) {
      this._showElements();
    } else {
      this._hideElements();
    }
  }

  // Add listeners to each checkbox target for either the specified event
  // or, by default, the checkbox event.
  _addCheckboxListeners() {
    this.checkboxTargets.forEach((target) => {
      target.addEventListener('change', () => this._checkCheckboxes());
    });
  }

  // Set up listeners and establish the starting state of the elements by running
  // an initial check on the checkboxes.
  initialize() {
    this._setToggleClass();
    this._addCheckboxListeners();
    this._checkCheckboxes();
  }
}
