import { Controller } from 'stimulus';

// Controller that is specific to Draft review steps approval form.
// - when reviewer is changed, update the remote link to allow the individual field to be submitted
//   either on it's own or as part of the larger form
//
// @target [Element] select the Reviewer select element.
// @target [Element] link the remote submit link.
export default class extends Controller {
  static targets = ['select', 'link'];

  initialize() {
    this.selectTarget.addEventListener('change', this._updateLink.bind(this));
  }

  _updateLink() {
    const { href } = this.linkTarget;

    this.linkTarget.href = href.replace(/reviewer_id%5D=.*/, `reviewer_id%5D=${this.selectTarget.value}`);
  }
}
