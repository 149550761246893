import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['title'];

  updateTitle(event) {
    const content = event.currentTarget.value;
    const defaultTitle = 'KML Images';

    if (content !== '') {
      this.titleTarget.innerHTML = content;
    } else {
      this.titleTarget.innerHTML = defaultTitle;
    }
  }
}
