import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'element', 'control'];

  // bind change event
  initialize() {
    if (this.selectTarget) this.selectTarget.addEventListener('change', this._selectChange.bind(this));
  }

  // show/hide elements if selected value is part of data-values
  _selectChange() {
    const { values } = this.selectTarget.dataset;
    if (JSON.parse(values).includes(this.selectTarget.value)) {
      this._showElements();
    } else {
      this._hideElements();
    }
  }

  // Iterate over all element targets and add the disabled attribute to
  // disable them.
  _showElements() {
    this.elementTargets.forEach((element) => {
      element.classList.remove('is-hidden');
    });
    this.controlTargets.forEach((element) => {
      element.disabled = false;
      element.dispatchEvent(new Event('enabled'));
    });
  }

  // Iterate over all element targets and remove the disabled attribute to
  // enable them.
  _hideElements() {
    this.elementTargets.forEach((element) => {
      element.classList.add('is-hidden');
    });
    this.controlTargets.forEach((element) => {
      element.disabled = true;
      element.dispatchEvent(new Event('disabled'));
    });
  }
}
