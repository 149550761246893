import { Controller } from 'stimulus';

// This controller is used to control interacts between elements on the job delivery form located
// at app/views/jobs/deliveries/_modal_form.html.erb
//
// @target delivered_at Date input to watch for changes on
// @target mark_as_complete Checkbox that changes job delivery status
export default class extends Controller {
  static targets = ['deliveredAt', 'markAsComplete', 'completeStatus'];

  setCompleted(event) {
    const { target } = event;
    this._markComplete(target);
  }

  setDeliveredAt(event) {
    const { target } = event;
    if (this.deliveredAtTarget.value.length > 0) { return; }
    if (!target.checked) { return; }
    this.deliveredAtTarget.valueAsDate = new Date();
  }

  _markComplete(element) {
    const { value } = element;
    this.markAsCompleteTarget.checked = value.length > 0;
  }

  initialize() {
    if (this.completeStatusTarget.value === 'true') { this._markComplete(this.markAsCompleteTarget); }
  }
}
