import { Controller } from 'stimulus';

// This controller adds automatic uppercasing to any input.
// The element that adds the controller must throw the 'input' event.
export default class extends Controller {
  static targets = [];

  // Whenever an element adds this controller, it will listen for input
  // events on that element and upcase the value.
  initialize() {
    this.element.addEventListener('input', () => {
      this.element.value = this.element.value.toUpperCase();
    });
  }
}
