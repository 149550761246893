import { Controller } from 'stimulus';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

// This controller adds Clipper.js functionality to any file input.
export default class extends Controller {
  static targets = [
    'croppedOffsetX',
    'croppedOffsetY',
    'croppedWidth',
    'croppedHeight',
    'fileField',
    'fileName',
    'imageContainer',
    'modal',
    'removeFileButton',
  ];

  // Show the modal window to the user using Bulma classes.
  showModal() {
    // Toggle the Bulma visibility class
    this.modalTarget.classList.add('is-active');
  }

  // Hide the modal window from the user using Bulma classes.
  hideModal() {
    // Toggle the Bulma visibility class
    this.modalTarget.classList.remove('is-active');
  }

  // Set the value of the file field back to null so that no image
  // is submitted with the form.
  resetFileFieldValue() {
    this.fileFieldTarget.value = null;
  }

  // Set the preview name of the file back to the default text.
  resetFileNameContent() {
    this.fileNameTarget.textContent = 'Waiting for uploaded file...';
  }

  // For when the user is leaving the image cropping field without cropping the image
  // successfully. We want to reset the field and hide the modal.
  exit() {
    // Clear out the file field value so that no file is set to upload.
    this.resetFileFieldValue();

    this.hideModal();
  }

  // Create an image tag with a file blob from FileReader as the source,
  // and with some styles that will help Clipper.js and Bulma play nicely
  createClipperImageTag(source) {
    const imageTag = document.createElement('img');
    imageTag.src = source;
    imageTag.style.maxWidth = '100%';
    imageTag.style.maxHeight = '65vh';

    return imageTag;
  }

  // Test a file's MIME types to see if it is croppable. We basically
  // just want to test whether the image is a JPG, PNG, or BMP.
  //
  // @return [Boolean] true if file is croppable format, false if not
  croppableFile(file) {
    const mimeRegex = new RegExp('^image/(png|jpeg|bmp)$');
    return mimeRegex.test(file.type);
  }

  // Whenever a file is added to the file input field by a user, we want to
  // give them the opportunity to crop the image. We utilize the Cropper.js library.
  fileAdded() {
    if (this.fileFieldTarget.files && this.fileFieldTarget.files[0]) {
      if (!this.croppableFile(this.fileFieldTarget.files[0])) {
        this.resetFileFieldValue();
        return;
      }

      const reader = new FileReader();

      // Provide access to Stimulus controller within callback below
      const controller = this;

      // Read the file from the file field input, then provide the cropping interface
      reader.onload = (event) => {
        // Add the FileReader image to the DOM
        const imageTag = controller.createClipperImageTag(event.currentTarget.result);

        // Replace the image container content with the new image tag
        controller.imageContainerTarget.innerHTML = '';
        controller.imageContainerTarget.appendChild(imageTag);

        // Initialize the Cropper.js container with some helpful defaults.
        // See https://github.com/fengyuanchen/cropperjs for details.
        controller.cropper = new Cropper(imageTag, {
          viewMode: 2,
          dragMode: 'move',
          cropBoxResizable: true,
          aspectRatio: 1,
        });

        // Open up the modal now that everything has been set up
        controller.showModal();
      };

      // Read the file blob from the file field input
      reader.readAsDataURL(this.fileFieldTarget.files[0]);
    }
  }

  // Provides the ability to delete files from the object, if they are persisted.
  // If we're still just editing locally, then we reset the value of the field and
  // reset the placeholder text.
  removeFile() {
    this.removeFileButtonTarget.classList.add('is-loading');

    const { attachmentId } = this.removeFileButtonTarget.dataset;

    // If the file has been persisted, we need to purge it from remote
    if (attachmentId) {
      Rails.ajax({
        url: `/attachments/${attachmentId}`,
        type: 'DELETE',
        success: () => {
          delete this.removeFileButtonTarget.dataset.attachmentId;
          this.resetFileFieldValue();
          this.resetFileNameContent();
          this.removeFileButtonTarget.classList.remove('is-loading');
        },
        error: () => {
          this.removeFileButtonTarget.classList.remove('is-loading');
        },
      });
    } else {
      // If there's no persisted data, just update the text and reset the form field.
      this.resetFileFieldValue();
      this.resetFileNameContent();
      this.removeFileButtonTarget.classList.remove('is-loading');
    }
  }

  // Assign crop data from Cropper.js to hidden input fields that we can provide to Rails
  // for cropping the image.
  setCropInputFields(cropData) {
    this.croppedWidthTarget.value = cropData.width;
    this.croppedHeightTarget.value = cropData.height;
    this.croppedOffsetXTarget.value = cropData.x;
    this.croppedOffsetYTarget.value = cropData.y;
  }

  // Called when the user is done cropping the image and is happy with the result. We set the
  // hidden crop input fields and hide the modal again.
  submit() {
    // Pass the Cropper.js cropData to the Rails form for image processing
    this.setCropInputFields(this.cropper.getData());

    // Update the file name in the uploader
    this.fileNameTarget.textContent = this.fileFieldTarget.files[0].name;

    this.hideModal();
  }
}
