import { Controller } from 'stimulus';

// Add some basic functionality for updating notifications as "Read" whenever the notification
// dropdown is activated.
//
// @var [String] activeClass the class to watch for on the notification dropdown container.
//
// @target [Array<Element>] notification the notification element(s) that can be marked as read.
export default class extends Controller {
  static targets = ['notification'];

  // Iterate over all notification targets that have been specified, and mark them as "read" if
  // they are currently unread.
  _markNotificationsAsRead() {
    this.notificationTargets.forEach((element) => {
      if (element.dataset.read === 'false') {
        Rails.ajax({
          url: element.dataset.markReadUrl,
          type: 'POST',
          dataType: 'script',
          success: () => {
            element.dataset.read = 'true';
          },
        });
      }
    });
  }

  // Check if the notification container is active. If it is, mark the appropriate
  // notifications as "read".
  _updateNotificationContainer() {
    if (this.element.classList.contains(this.activeClass)) {
      this._markNotificationsAsRead();
    }
  }

  // Set up intitial variables, and start watching the notification container to ensure that
  // it is updated appropriately when activated to mark notifications as read.
  initialize() {
    this.activeClass = 'is-active';

    this.observer = new MutationObserver(() => {
      this._updateNotificationContainer();
    });

    this.observer.observe(this.element, { attributes: true, attributeFilter: ['class'] });
    this._updateNotificationContainer();
  }
}
