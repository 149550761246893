import { Controller } from 'stimulus';

// Add auto-dismissing behavior to elements with a progress bar.
export default class extends Controller {
  static targets = [];

  // Slowly decrement the progress bar on the element, removing the element when complete.
  _updateProgressBar() {
    const progressBar = this.element.querySelector('progress.progress');

    if (progressBar === null) {
      return;
    }

    const finalPercentage = 0;
    let currentPercentage = progressBar.value;

    const update = setInterval(() => {
      if (currentPercentage <= finalPercentage) {
        clearInterval(update);
        progressBar.dispatchEvent(new Event('timeout'));
      }

      currentPercentage -= 0.05;
      progressBar.value = currentPercentage;
    }, 1);
  }

  initialize() {
    this._updateProgressBar();
  }
}
