import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  _updateControllerTopStyle() {
    if (this.element.children.length > 0 && window.scrollY <= this.navbarHeightPixels) {
      this.element.style.top = `${this.navbarHeightPixels - window.scrollY}px`;
    } else if (this.element.style.top !== '0px') {
      this.element.style.top = '0px';
    }
  }

  initialize() {
    const navbar = document.querySelector('nav.navbar');

    if (navbar === null) {
      return;
    }

    this.navbarHeightPixels = navbar.clientHeight;
    this._updateControllerTopStyle();

    window.addEventListener('scroll', () => {
      this._updateControllerTopStyle();
    });

    this.observer = new MutationObserver(() => {
      this._updateControllerTopStyle();
    });

    this.observer.observe(this.element, { childList: true });
  }

  // Tear down the observer when the controller is destroyed.
  disconnect() {
    this.observer.disconnect();
  }
}
