import { Controller } from 'stimulus';

// Facilitate streaming data from the PointDataFileChannel.
export default class extends Controller {
  static targets = ['container', 'totalCounter'];

  _updateStatusCounts(statusCounts) {
    const chart = Chartkick.charts[this.statusChartIdentifier];

    if (this.hasTotalCounterTarget) {
      this.totalCounterTarget.innerHTML =
        statusCounts.success +
        statusCounts.warning +
        statusCounts.error;
    }

    chart.updateData({
      Success: statusCounts.success,
      Warning: statusCounts.warning,
      Error: statusCounts.error,
      Unprocessed: statusCounts.unprocessed,
    });
  }

  _updateCodeUseCounts(codeUseCounts) {
    const chart = Chartkick.charts[this.codeUseChartIdentifier];

    chart.updateData(codeUseCounts);
  }

  _finalStatusUpdate(statusCounts) {
    const chart = Chartkick.charts[this.statusChartIdentifier];

    if (this.hasTotalCounterTarget) {
      this.totalCounterTarget.innerHTML =
        statusCounts.success +
        statusCounts.warning +
        statusCounts.error;
    }

    chart.updateData({
      Success: statusCounts.success,
      Warning: statusCounts.warning,
      Error: statusCounts.error,
    });
  }

  _refreshPageContent() {
    Rails.ajax({
      url: this.data.get('refreshUrl'),
      type: 'GET',
    });
  }

  // Set up the connection to the ActionCable channel for the specific PointDataFile.
  // Listen for data, then add it to the DOM when it arrives.
  initialize() {
    this.statusChartIdentifier = 'bss-point-data-file-status-chart';
    this.codeUseChartIdentifier = 'bss-point-data-file-code-use-chart';

    App.cable = ActionCable.createConsumer();
    App.cable.subscriptions.create(
      {
        channel: 'PointDataFileChannel',
        id: this.data.get('id'),
      },
      {
        received: (data) => {
          this._updateStatusCounts(data.field_point_status_counts);
          this._updateCodeUseCounts(data.field_code_use_counts);

          if (data.field_point_status_counts.unprocessed === 0) {
            this._finalStatusUpdate(data.field_point_status_counts);
            this._refreshPageContent();
          }
        },
      },
    );
  }
}
