import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'input'];

  // bind change event
  initialize() {
    if (this.selectTarget) this.selectTarget.addEventListener('change', this._selectChange.bind(this));
  }

  // clear input if selected value is empty
  _selectChange() {
    if (!this.selectTarget.value) { this.inputTarget.value = null; }
  }
}
