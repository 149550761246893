import { Controller } from 'stimulus';

// Pagination Controller. Currently, this takes care of the following functionality:
// - Navigate to current page with per_page parameter set
//
// @target [Element] perPage the perPage select element.
export default class extends Controller {
  static targets = ['perPage'];

  // navigate to current page with updated per_page param
  //
  // @param [event] perPage change event.
  updatePerPage() {
    const { dataset, value } = this.perPageTarget;
    const url = dataset.url.replace('PER_PAGE_SUBSTITUTION', value);
    const formatReg = /.*[.]js[?].*/i;

    if (formatReg.test(url)) {
      Rails.ajax({
        url,
        type: 'GET',
      });
    } else {
      Turbolinks.visit(url); // eslint-disable-line no-undef
    }
  }
}
