import { Controller } from 'stimulus';

// Support the display of one or more Jobs on a Google map.
//
// @data [String] data a string of job data in a JSON format.
//                     Must include site_latitude, site_longitude, and identifier.
//
// @example
//   <figure data-controller="google--jobs-map" data-google--jobs-map-data="{data}"></figure>
export default class extends Controller {
  // Clear out all markers currently on the map.
  _removeCurrentMapMarkers() {
    this.markers.forEach(marker => marker.setMap(null));
    this.markers.length = 0;
    this.markerBounds = new google.maps.LatLngBounds();
  }

  // Move the browser to a marker's URL.
  _navigateToMarkerLink(marker) {
    window.location.href = marker.url;
  }

  // Given a single Job data object, add it to the map as a marker.
  //
  // @param [Object] job a JSON object containing job data.
  _addMapMarker(job, icon = null) {
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(job.site_latitude, job.site_longitude),
      title: job.identifier,
      icon,
      draggable: false,
      animation: google.maps.Animation.DROP,
      url: job.google_directions_url,
      labelClass: 'todo-list-marker-label',
    });

    marker.setMap(this.map);

    marker.addListener('click', () => {
      this._navigateToMarkerLink(marker);
    });

    this.markers.push(marker);
    this.markerBounds.extend(marker.getPosition());
  }

  // Extract the job data and add markers to the map for each job.
  _addMapMarkers() {
    let jobData = JSON.parse(this.data.element.dataset['google-JobsMapData']);

    if (!Array.isArray(jobData)) {
      jobData = [jobData];
    }

    jobData.forEach((job) => {
      this._addMapMarker(job);
    });
  }

  // Adjust the map boundary based on the set of marker boundaries.
  //
  // @see https://developers.google.com/maps/documentation/javascript/reference/3/map#Map.fitBounds
  _updateMapBounds() {
    this.map.setCenter(this.markerBounds.getCenter());
    this.map.fitBounds(this.markerBounds);
  }

  // If the map is zoomed in more than the default zoom level, zoom back
  // out to the default level.
  _updateMapZoom() {
    if (this.map.getZoom() > this.defaultZoomLevel) {
      this.map.setZoom(this.defaultZoomLevel);
    }
  }

  // Refresh the map markers on the map.
  _updateMapMarkers() {
    this._removeCurrentMapMarkers();
    this._addMapMarkers();
    this._updateMapBounds();
  }

  // Set up the map with an initial position centered on Atlanta.
  // Add markers to the map, then update the map boundaries to include all of them.
  _setUpMap() {
    const atlanta = { lat: 33.753746, lng: -84.386330 };
    const startingLocation = new google.maps.LatLng(atlanta.lat, atlanta.lng);

    const options = {
      zoom: this.defaultZoomLevel,
      center: startingLocation,
      mapTypeId: this.defaultMapType,
      streetViewControl: false,
    };

    this.map = new google.maps.Map(this.element, options);

    this._updateMapMarkers();

    google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      this._updateMapZoom();
    });
  }

  // Watch for changes in the job data that was provided to the controller. If this
  // job data changes, the map pins need to be updated.
  _watchMapJobData() {
    const contentObserver = new MutationObserver(() => {
      this._updateMapMarkers();
    });

    contentObserver.observe(
      this.element,
      {
        attributes: true,
        attributeFilter: ['data-google--jobs-map-data'],
      },
    );
  }

  // Google Maps boundaries don't actually update when the map element is hidden, so we
  // must monitor the visibility and ensure that the boundaries are updated when it is
  // not hidden.
  //
  // @see https://developers.google.com/maps/documentation/javascript/reference/3/map#Map.fitBounds
  _watchMapVisibility() {
    const contentObserver = new MutationObserver(() => {
      if (!this.element.classList.contains('is-hidden')) {
        this._updateMapBounds();
      }
    });

    contentObserver.observe(
      this.element,
      {
        attributes: true,
        attributeFilter: ['class'],
      },
    );
  }

  // Set up initial variables, set up a watcher for the job data, and set up the map view.
  initialize() {
    this.defaultZoomLevel = 18;
    this.defaultMapType = google.maps.MapTypeId.ROADMAP;
    this.markers = [];

    this._setUpMap();

    this._watchMapJobData();
    this._watchMapVisibility();
  }
}
