import { Controller } from 'stimulus';

// Allow any type to be confirmable
// @example with a select
// <form data-controller="confirmable">
//   <select data-target="confirmable.select"
//           data-message="confirm?">
//     <option value="">Test</option>
//   </select>
// </form>
//
export default class extends Controller {
  static targets = ['select'];

  initialize() {
    this.selectTarget.addEventListener('focus', this.setPreviousValue.bind(this));
    this.selectTarget.addEventListener('change', this.confirmDialog.bind(this));
  }

  setPreviousValue() {
    this.previousValue = this.selectTarget.value;
  }

  confirmDialog() {
    if (this.confirming) return;
    this.confirming = true;

    const { message } = this.selectTarget.dataset;
    this.confirming = true;
    const conf = window.confirm(message); // eslint-disable-line no-alert
    if (conf === false) this.selectTarget.value = this.previousValue;
    // set confirming to false after event cycle
    setTimeout(() => { this.confirming = false; }, 1);
  }
}
