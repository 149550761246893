import { Controller } from 'stimulus';

// This is a very basic and specific controller that detects whether
// or not the user has entered a ten digit telephone number for the
// SMS login page.
//
// @target [Element] telephoneInput the input to check for ten digits.
// @target [Element] submitButton the button to enable when ten digits are present.
export default class extends Controller {
  static targets = ['submitButton', 'telephoneInput'];

  // Convert the phone number to only digits, then count them.
  // If there are ten, allow the user to submit the form.
  handleInput() {
    const phoneNumber = this.telephoneInputTarget.value.replace(/\D/g, '');

    if (phoneNumber.length === 10) {
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', true);
    }
  }
}
