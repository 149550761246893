import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'payrollCategorySelect',
    'jobSelect',
    'regionSelect',
    'hourlySelectsWrapper',
  ];

  initialize() {
    this.jobs = [];
    // wait for nested choices select controller to initialize
    setTimeout(() => {
      if (this.jobSelectTarget.value !== '') {
        this._refreshJobs(this.jobSelectTarget.value);
      }
    }, 1);

    // add AJAX autocomplete functionality to the choices element
    this.jobSelectTarget.addEventListener('search', (event) => {
      const text = event.detail.value;

      if (text.length >= 3) {
        this._refreshJobs(text);
      }
    });
  }

  toggleHourlySelects(_event) {
    const select = this.payrollCategorySelectTarget;
    const option = select.options[select.selectedIndex];

    if (option.text === 'Hourly' || option.text === 'Salary') {
      this.showJobsSelect();
    } else {
      this.hourlySelectsWrapperTarget.classList.add('is-hidden');
    }
  }

  showJobsSelect() {
    this.hourlySelectsWrapperTarget.classList.remove('is-hidden');
  }

  autocompleteJobs(event) {
    const identifier = event.target.value;
    this._refreshJobs(identifier);
  }

  setRegionFromJob(_event) {
    const job = this.jobs.find(j => j.id.toString() === this.jobSelectTarget.value.toString());
    this.regionChoicesObject.setChoiceByValue(job.region_id.toString());
  }

  // Fetch Jobs from the server
  _refreshJobs(identifier) {
    Rails.ajax({
      url: '/autocomplete/jobs.json',
      type: 'get',
      dataType: 'json',
      data: new URLSearchParams({
        q: identifier,
        limit: 5,
      }).toString(),
      success: (response) => {
        if (response.length !== 0) {
          this.jobs = response;
          this.jobChoicesObject.setChoices(
            this._mapJobsForSelect(response),
            'value',
            'label',
            true,
          );

          this.jobChoicesObject.enable();
        }
      },
    });
  }

  _mapJobsForSelect(jobs) {
    return jobs.map(object => ({
      label: object.autocomplete_identifier,
      value: object.id.toString(),
    }));
  }

  // Retrieve the Company Choices controller that is available on this element, and from
  // that controller, retrieve the Choices object.
  get regionChoicesObject() {
    return this.application.getControllerForElementAndIdentifier(
      this.regionSelectTarget,
      'choices--select',
    ).choicesObject;
  }

  // Retrieve the Company Choices controller that is available on this element, and from
  // that controller, retrieve the Choices object.
  get jobChoicesObject() {
    return this.application.getControllerForElementAndIdentifier(
      this.jobSelectTarget,
      'choices--select',
    ).choicesObject;
  }
}
