import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['field'];

  click(e) {
    e.preventDefault();
    this.fieldTarget.value = '1';
    this.fieldTarget.dispatchEvent(new Event('change'));
  }
}
