import { Controller } from 'stimulus';

// This controller is meant to be used on any page where forms are dynamically
// edited on the page with AJAX.
//
// @var [String] loadingClass the class to add to buttons when they are loading.
// @var [String] hiddenClass the class to add to elements when they are hidden.
//
// @target [Element]        contentContainer element to hide while editing is taking place
// @target [Element]        formContainer    element to hide show while editing is taking place
export default class extends Controller {
  static targets = [
    'contentContainer',
    'disableOnSubmit',
    'focusField',
    'formContainer',
  ]

  // Display the edit form and hide the object data
  replaceContentWithForm(e) {
    if (e.preventDefault) e.preventDefault();

    this.contentContainerTarget.classList.add(this.hiddenClass);
    this.formContainerTarget.classList.remove(this.hiddenClass);

    if (this.hasFocusFieldTarget) {
      this.focusFieldTarget.focus();
      this.focusFieldTarget.select();
    }
  }

  // Hide the edit form and display the object content
  replaceFormWithContent() {
    this.contentContainerTarget.classList.remove(this.hiddenClass);
    this.formContainerTarget.classList.add(this.hiddenClass);
  }

  _addFormListeners(formElement) {
    formElement.addEventListener(this.ajaxSendEvent, () => {
      this.disableOnSubmitTargets.forEach((element) => {
        element.setAttribute('disabled', true);
        element.classList.add(this.loadingClass);
      });
    });

    formElement.addEventListener(this.ajaxCompleteEvent, () => {
      this.disableOnSubmitTargets.forEach((element) => {
        element.removeAttribute('disabled');
        element.classList.remove(this.loadingClass);
      });
    });
  }

  _setControllerVariables() {
    this.hiddenClass = 'is-hidden';
    this.loadingClass = 'is-loading';
    this.ajaxSendEvent = 'ajax:send';
    this.ajaxCompleteEvent = 'ajax:complete';
  }

  // Set up some initial variables for Bulma classes.
  initialize() {
    this._setControllerVariables();
    this._addFormListeners(this.formContainerTarget.querySelector('form'));
  }
}
