import '@stimulus/polyfills';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import '../stylesheets/application.scss';

import '../lib/animation_event_setter';
import '../lib/site_comment_cache_fix';
import '../lib/history_state_manager';

require('trix');
require('@rails/actiontext');

const application = Application.start();
const context = require.context('controllers', true, /.js$/);
application.load(definitionsFromContext(context));
