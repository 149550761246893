import { Controller } from 'stimulus';

// This controller adds the ability to toggle dropdowns in the navbar open and closed.
//
// @var [String] activeClass the class to use to activate navbar dropdown content.
//
// @example
//   <div class="navbar-item has-dropdown" data-controller="specific--navbar-dropdown#toggle">
//     <a class="navbar-link" data-action="click->specific--navbar-dropdown#toggle">
//       Open Dropdown
//     </a>
//
//     <div class="navbar-dropdown">
//       Dropdown content, which is hidden until the `navbar-item` is active.
//     </div>
//   </div>
export default class extends Controller {
  static targets = [];

  // Externally accessed method to open up the navbar dropdown menu.
  toggle() {
    this.element.classList.toggle('is-active');
  }

  // If a click is detected somewhere else on the page while the dropdown is active, deactivate it.
  _watchForWindowClicks() {
    window.addEventListener('click', (event) => {
      const isActive = this.element.classList.contains(this.activeClass);
      const eventOutsideElement = !this.element.contains(event.target);

      if (isActive && eventOutsideElement) {
        this.element.classList.remove(this.activeClass);
      }
    });
  }

  // Set up initial variables, and set up another watcher for other clicks in the window.
  initialize() {
    this.activeClass = 'is-active';
    this._watchForWindowClicks();
  }
}
