import { Controller } from 'stimulus';

// Add some basic functionality to enable the form submission when we have
// a valid verification code, and to ensure that the input has the correct
// format.
//
// @target [Element] submitButton the button the enable when we have a valid input
// @target [Element] input the code input to check before allowing form submission
export default class extends Controller {
  static targets = ['submitButton', 'input'];

  // Normalize the code text and enable/disable form submission.
  handleInput() {
    // Strip out non-digit characters.
    let code = this.inputTarget.value.replace(/\D/g, '');
    code = code.substring(0, 6);

    if (code.length === 6) {
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', true);
    }

    switch (code.length) {
      case 4:
      case 5:
      case 6:
        code = `${code.substring(0, 3)}-${code.substring(3, 6)}`;
        break;
      default:
    }

    this.inputTarget.value = code;
  }
}
