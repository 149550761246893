import { Controller } from 'stimulus';
import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.min.css';

// Reusable component for a Choices.js select element initialization.
//
// @see https://github.com/jshjohnson/Choices
//
// @data [Boolean] removable whether or not there should be a "clear" button
//                           on the dropdown. Default false.
// @data [Boolean] searchable whether or not there should be a "search" menu
//                            on the dropdown. Default true.
export default class extends Controller {
  static targets = [];

  initialize() {
    // if element is disabled on init, the options are unavailable even when choices select is
    // enabled later on
    const { disabled } = this.element;
    this.element.disabled = false;
    let removable = false;
    if (this.data.has('removable')) {
      removable = (this.data.get('removable') === 'true');
    }

    let searchable = true;
    if (this.data.has('searchable')) {
      searchable = (this.data.get('searchable') === 'true');
    }

    this.choicesObject = new Choices(this.element, {
      position: this.data.get('position') || 'bottom',
      removeItemButton: removable || this.element.multiple,
      searchEnabled: searchable,
      shouldSort: false,
    });

    window.addEventListener('turbolinks:before-render', () => {
      this.choicesObject.destroy();
    });

    if (disabled) {
      this.choicesObject.disable();
      this.choicesObject.hideDropdown();
    }

    // Whenever the form input sends a 'disabled' event, make sure
    // that we disable the associated Choices element.
    this.element.addEventListener('disabled', () => {
      this.choicesObject.disable();
      this.choicesObject.hideDropdown();
    });

    // Whenever the form input sends an 'enabled' event, make sure
    // that we disable the associated Choices element.
    this.element.addEventListener('enabled', () => {
      this.choicesObject.enable();
    });

    // Whenever the form input sends a 'reset' event, make sure that
    // any active items are cleared, if possible.
    this.element.addEventListener('reset', () => {
      this.choicesObject.removeActiveItems();
    });

    this.element.disabled = disabled;
  }
}
