import { Controller } from 'stimulus';

// Controller that is specific to Jobs. Currently, this takes care of the following functionality:
// - Update Company select when Client select is changed
//
// @target [Element] clientSelect the Client select element.
// @target [Element] companySelect the Company select element.
export default class extends Controller {
  static targets = ['clientSelect', 'companySelect', 'contactSelect', 'cadConversionRequiredCheckbox'];

  // Fetch companies from the server by a given client ID, then update
  // the company dropdown to show those values.
  //
  // @param [Integer] clientId the Id of the client to fetch companies for.
  _refreshCompaniesFromClient(clientId) {
    Rails.ajax({
      url: `/clients/${clientId}/companies.json`,
      type: 'GET',
      success: (response) => {
        if (response.length !== 0) {
          this.companyChoicesObject.setChoices(
            response.map(object => ({ label: object.name, value: object.id.toString() })),
            'value',
            'label',
            true,
          );

          this.companyChoicesObject.enable();
        } else {
          this.companyChoicesObject.disable();
        }
      },
      error: () => {
        this.companyChoicesObject.disable();
      },
    });
  }

  _refreshContactsFromClient(clientId) {
    Rails.ajax({
      url: `/clients/${clientId}/contacts.json`,
      type: 'GET',
      success: (response) => {
        if (response.length !== 0) {
          this.contactChoicesObject.setChoices(
            response.map((object) => {
              const newObject = {
                label: `${object.first_name} ${object.last_name}`,
                value: object.id.toString(),
              };

              return newObject;
            }),
            'value',
            'label',
            true,
          );

          this.contactChoicesObject.enable();
        } else {
          this.contactChoicesObject.disable();
        }
      },
      error: () => {
        this.companyChoicesObject.disable();
      },
    });
  }

  _clearClientDependents() {
    this.companyChoicesObject.clearStore();
    this.contactChoicesObject.clearStore();
  }

  // Callback to use for events to update the company select whenever
  // the client select value is changed.
  //
  // @param [Event] event the input event from the client select.
  updateClientDependents(event) {
    const clientId = event.currentTarget.value;

    this._clearClientDependents();
    this._updateCadRequiredCheckbox(clientId);

    this._refreshCompaniesFromClient(clientId);
    this._refreshContactsFromClient(clientId);
  }

  // Auto-check the CAD conversion required checkbox if the selected client
  // prefers to require it.
  _updateCadRequiredCheckbox(clientId) {
    const clients = JSON.parse(this.clientSelectTarget.dataset.collection);
    const client = clients.find(c => c.id.toString() === clientId);

    if (client && client.cad_conversion_required) {
      this.cadConversionRequiredCheckboxTarget.setAttribute('checked', 'checked');
    } else {
      this.cadConversionRequiredCheckboxTarget.removeAttribute('checked');
    }
  }

  // Ensure that if a client is specified on page load, that we refresh the company
  // select box to have accurate values.
  initialize() {
    // wait for nested choices select controller to initialize
    setTimeout(() => {
      if (this.clientSelectTarget.value !== '') {
        this._refreshCompaniesFromClient(this.clientSelectTarget.value);
        this._refreshContactsFromClient(this.clientSelectTarget.value);
      }
    }, 1);
  }

  // Retrieve the Company Choices controller that is available on this element, and from
  // that controller, retrieve the Choices object.
  get companyChoicesObject() {
    return this.application.getControllerForElementAndIdentifier(
      this.companySelectTarget,
      'choices--select',
    ).choicesObject;
  }

  // Retrieve the Contact Choices controller that is available on this element, and from
  // that controller, retrieve the Choices object.
  get contactChoicesObject() {
    return this.application.getControllerForElementAndIdentifier(
      this.contactSelectTarget,
      'choices--select',
    ).choicesObject;
  }
}
