import { Controller } from 'stimulus';

// Allow any element on a page to be collapsed and expanded.
//
// @variable [String] hiddenClass the class to use for hiding elements on the page.
//
// @target [Element] content         the element to collapse and expand.
// @target [Element] shownWhenClosed the elements to show when the content is collapsed.
// @target [Element] shownWhenOpen   the elements to show when the content is expanded.
//
// @example
//   <div data-controller="collapsable">
//     <h1 data-action="click->collapsable#toggleContentVisibility">
//       Click me to collapse/expand content
//     </h1>
//
//     <p data-target="collapsable.shownWhenClosed">I get shown when the content is collapsed.</p>
//     <p data-target="collapsable.shownWhenOpen">I get shown when the content is expanded.</p>
//
//     <div data-target="collapsable.content">
//       I am shown when expanded!
//     </div>
//   </div>
export default class extends Controller {
  static targets = ['content', 'shownWhenClosed', 'shownWhenOpen'];

  // Set up the initial variables that the controller depends on.
  _setControllerVariables() {
    this.hiddenClass = 'is-hidden';
  }

  // Determine whether or not the content target is hidden.
  //
  // @return [Boolean] true if the content target is hidden, otherwise false.
  _contentHidden() {
    if (this.hasContentTarget) {
      if (this.contentTarget.classList.contains(this.hiddenClass)) {
        return true;
      }
    }

    return false;
  }

  // Update shownWhenOpen and shownWhenClosed targets for collapsed content.
  _setClosedState() {
    this.shownWhenOpenTargets.forEach((element) => {
      element.classList.add(this.hiddenClass);
    });

    this.shownWhenClosedTargets.forEach((element) => {
      element.classList.remove(this.hiddenClass);
    });
  }

  // Update shownWhenOpen and shownWhenClosed targets for expanded content.
  _setOpenState() {
    this.shownWhenOpenTargets.forEach((element) => {
      element.classList.remove(this.hiddenClass);
    });

    this.shownWhenClosedTargets.forEach((element) => {
      element.classList.add(this.hiddenClass);
    });
  }

  // Update shownWhenOpen and shownWhenClosed targets as appropriate.
  _updateToggledElements() {
    if (this.hasContentTarget) {
      if (this._contentHidden()) {
        this._setClosedState();
      } else {
        this._setOpenState();
      }
    }
  }

  // Expand the content.
  _showContent() {
    if (this.hasContentTarget) {
      this.contentTarget.classList.remove(this.hiddenClass);
    }
  }

  // Collapse the content.
  _hideContent() {
    if (this.hasContentTarget) {
      this.contentTarget.classList.add(this.hiddenClass);
    }
  }

  // Flip the content target to the opposite of its current state.
  _toggleContentHidden() {
    if (this.hasContentTarget) {
      if (this._contentHidden()) {
        this._showContent();
      } else {
        this._hideContent();
      }
    }
  }

  // Determine the initial state of the content.
  _setInitialElementState() {
    if (this.hasContentTarget) {
      this._updateToggledElements();
    }
  }

  // Public function to be called whenever the element should be expanded/collapsed.
  toggleContentVisibility() {
    this._toggleContentHidden();
    this._updateToggledElements();
  }

  // Set up initial variables, and hide/show elements appropriately.
  initialize() {
    this._setControllerVariables();
    this._setInitialElementState();
  }
}
