import { Controller } from 'stimulus';
import parseDMS from 'parse-dms';

// Handle the interactions for a job search by address.
export default class extends Controller {
  static targets = ['addressInput', 'form', 'latitude', 'longitude', 'map'];

  // Set up Google autocomplete on the street address input.
  _setUpAddressAutoComplete() {
    if (!this.hasAddressInputTarget) {
      return;
    }

    const options = {
      fields: [
        'geometry.location',
      ],
    };

    this.autocomplete = new google.maps.places.Autocomplete(
      this.addressInputTarget,
      options,
    );

    this.autocomplete.addListener('place_changed', () => {
      const { geometry } = this.autocomplete.getPlace();

      if (!geometry) return;

      this.latLng = new google.maps.LatLng(
        geometry.location.lat(),
        geometry.location.lng(),
      );

      this.latitudeTarget.value = geometry.location.lat();
      this.longitudeTarget.value = geometry.location.lng();

      Rails.fire(this.formTarget, 'submit');
    });
  }

  // Add the initial map to the page.
  _setUpMap() {
    if (!this.hasMapTarget) {
      return;
    }

    const options = {
      zoom: this.defaultZoomLevel,
      center: this.latLng,
      mapTypeId: this.defaultMapType,
      streetViewControl: false,
    };

    this.map = new google.maps.Map(this.mapTarget, options);

    google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      this._updateMapZoom();
    });
  }

  // If the map is zoomed in more than the default zoom level, zoom back
  // out to the default level.
  _updateMapZoom() {
    if (this.map.getZoom() > this.defaultZoomLevel) {
      this.map.setZoom(this.defaultZoomLevel);
    }
  }

  // Watch for changes in the job data that was provided to the controller. If this
  // job data changes, the map pins need to be updated.
  _watchMapJobData() {
    const contentObserver = new MutationObserver(() => {
      this._updateMapMarkers();
    });

    contentObserver.observe(
      this.mapTarget,
      {
        attributes: true,
        attributeFilter: ['data-jobs'],
      },
    );
  }

  // Refresh the map markers on the map.
  _updateMapMarkers() {
    this._removeCurrentMapMarkers();
    this._addMapMarkers();
    this._updateMapBounds();
  }

  // Extract the job data and add markers to the map for each job.
  _addMapMarkers() {
    let jobData = JSON.parse(this.mapTarget.dataset.jobs);

    if (!Array.isArray(jobData)) {
      jobData = [jobData];
    }

    jobData.forEach((job) => {
      this._addMapMarker(job);
    });
  }

  // Given a single Job data object, add it to the map as a marker.
  //
  // @param [Object] job a JSON object containing job data.
  _addMapMarker(job = null, icon = null, position = null) {
    let url;
    let title;

    if (job) {
      url = `/jobs/${job.identifier}`;
      title = job.identifier;
    }

    const marker = new google.maps.Marker({
      position: position || new google.maps.LatLng(job.site_latitude, job.site_longitude),
      title: title || 'Current selection',
      icon,
      draggable: false,
      animation: google.maps.Animation.DROP,
      url,
    });

    marker.setMap(this.map);

    if (url) {
      marker.addListener('click', () => {
        this._navigateToMarkerLink(marker);
      });
    }

    this.markers.push(marker);
    this.markerBounds.extend(marker.getPosition());
  }

  // Adjust the map boundary based on the set of marker boundaries.
  //
  // @see https://developers.google.com/maps/documentation/javascript/reference/3/map#Map.fitBounds
  _updateMapBounds() {
    this._setControllerVariables();
    this._addMapMarker(null, { url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' }, this.latLng);
    this.map.setCenter(this.markerBounds.getCenter());
    this.map.fitBounds(this.markerBounds);
  }

  // Move the browser to a marker's URL.
  _navigateToMarkerLink(marker) {
    window.location.href = marker.url;
  }

  // Clear out all markers currently on the map.
  _removeCurrentMapMarkers() {
    this.markers.forEach(marker => marker.setMap(null));
    this.markers.length = 0;
    this.markerBounds = new google.maps.LatLngBounds();
  }

  _setControllerVariables() {
    this.markers = [];
    this.defaultZoomLevel = 13;
    this.defaultMapType = google.maps.MapTypeId.ROAD;

    if (this.latitudeTarget.value === '' || this.longitudeTarget.value === '') {
      const atlantaGeometry = { lat: 33.753746, lng: -84.386330 };
      this.latLng = new google.maps.LatLng(atlantaGeometry.lat, atlantaGeometry.lng);
    } else {
      this.latLng = new google.maps.LatLng(
        parseFloat(this.latitudeTarget.value),
        parseFloat(this.longitudeTarget.value),
      );
    }
  }

  _parseLatLon(str) {
    try {
      return parseDMS(str);
    } catch (e) {
      const latLon = str.split(', ');
      return { lat: latLon[0], lon: latLon[1] };
    }
  }

  formSubmit() {
    const { lat, lon } = this._parseLatLon(`${this.latitudeTarget.value}, ${this.longitudeTarget.value}`);
    this.latitudeTarget.value = lat;
    this.longitudeTarget.value = lon;
  }

  initialize() {
    this._setControllerVariables();
    this._setUpAddressAutoComplete();
    this._setUpMap();
    this._watchMapJobData();
  }
}
