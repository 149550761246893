import { Controller } from 'stimulus';

// This controller allows a control element to toggle css classes on the target
// elements, the css classes default to is-hidden if none specified
//
export default class extends Controller {
  static targets = ['toggled', 'disabled'];

  // toggle all specified classes in data-classes on toggled targets
  toggle() {
    this.toggledTargets.forEach((element) => {
      this._toggle(element);
    });
  }

  toggleAndDisable() {
    this.toggle();
    this._disable();
  }

  _disable() {
    this.disabledTargets.forEach((element) => {
      const wasDisabled = !!element.disabled;
      element.disabled = !element.disabled;

      if (wasDisabled) {
        element.dispatchEvent(new Event('enabled'));
      } else {
        element.dispatchEvent(new Event('disabled'));
      }
    });
  }

  _toggleClasses(element) {
    if (element.dataset.class) return element.dataset.class.split(' ');

    return ['is-hidden'];
  }

  _toggle(element) {
    const toggleClasses = this._toggleClasses(element);

    toggleClasses.forEach((toggleClass) => {
      if (element.classList.contains(toggleClass)) {
        element.classList.remove(toggleClass);
      } else {
        element.classList.add(toggleClass);
      }
    });
  }
}
