import { Controller } from 'stimulus';

// Given a set of tab buttons and matching containers of content,
// this controller will remove the is-active class from all tabs,
// unless it's the current tab in use, and hide all content containers,
// unless it's the content container for the tab in use.
//
// At the same time, this controller ensures that the active-tab (in use)
// and it's container of content are both displayed to the user on click.
//
// Each tab can optionally specify a `urlHash` data attribute which, when present,
// will cause this controller to read and set the URL to create permalinks for tabs.
//
// @target [Element] tab the button element clicked to show container content
//
// @example A set of tabs with corresponding containers of data
// <div data-controller="tabs">
//   <button data-target="tab" data-action="click->tabs#activate"
//      data-content-container="tab-content-1">Tab 1</button>
//   <button data-target="tab" data-action="click->tabs#activate"
//      data-content-container="tab-content-2">Tab 2</button>
//   <div id="tab-content-1">
//     Hello! This is tab 1 content.
//   </div>
//   <div id="tab-content-2">
//     Heyyyy, this is tab twooo.
//   </div>
// </div>
//
export default class extends Controller {
  static targets = ['initial', 'tab'];

  initialize() {
    this._setInitialTab();
  }

  _setInitialTab() {
    if (window.location.hash !== '') {
      const tabHash = window.location.hash.replace('#', '');
      const urlTab = this.tabTargets.filter(element => element.dataset.urlHash === tabHash)[0];

      if (urlTab) {
        this._activateTab(urlTab);
        return;
      }
    }

    this._activateTab(this.initialTarget);
  }

  _activateTab(activeTab) {
    this.tabTargets.forEach((currentTab) => {
      const tabContentID = currentTab.dataset.contentContainer;
      const tabContent = document.querySelector(`#${tabContentID}`);

      if (currentTab === activeTab) {
        currentTab.classList.add('is-active');

        if (tabContent !== null) {
          tabContent.classList.remove('is-hidden');
        }

        if (activeTab.dataset.urlHash !== undefined) {
          window.location.hash = `#${activeTab.dataset.urlHash}`;
        }
      } else {
        currentTab.classList.remove('is-active');

        if (tabContent !== null) {
          tabContent.classList.add('is-hidden');
        }
      }
    });
  }

  activate(event) {
    this._activateTab(event.currentTarget.closest('li.tab'));
  }
}
