import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  initialize() {
    this._setDistanceHelpers();
    this._setCanvasDimensions();

    Rails.ajax({
      url: this.element.dataset.requestPath,
      type: 'GET',
      success: (response) => {
        const object = response;
        this.context = this.element.getContext('2d');
        this.context.scale(2, 2);

        this.pointArray = this._getPointArray(object.preview_point_count);

        this._renderADCLineObjects(object.line_objects, object.preview_closable);
        this._drawPoints(object.point_color);
        this._renderADCBlockObjects(object.block_objects);
        this._renderADCTextObjects(object.text_objects);
      },
    });
  }

  // Add some helper distances as controller variables that can be used
  // when constructing points to render in the preview.
  _setDistanceHelpers() {
    this.width = parseInt(this.element.dataset.width, 10);
    this.height = parseInt(this.element.dataset.height, 10);

    this.halfWidth = this.width / 2;
    this.quarterWidth = this.width / 4;
    this.threeQuarterWidth = this.quarterWidth * 3;

    this.halfHeight = this.height / 2;
    this.quarterHeight = this.height / 4;
    this.threeQuarterHeight = this.quarterHeight * 3;
  }

  // Set the actual width of the canvas to something that is quite large,
  // while the style width and height stay at half that length. This allows
  // for a more focused viewport for retina displays.
  _setCanvasDimensions() {
    this.element.width = this.width * 2;
    this.element.height = this.height * 2;
    this.element.style.width = `${this.width}px`;
    this.element.style.height = `${this.height}px`;
  }

  // Return an array of points based on the provided point_count
  //
  // @param [Integer] previewPointCount the number of points to draw for a preview
  //
  // @return [Array<Array<Integer>>] an array of (x, y) coordinates for points
  _getPointArray(previewPointCount) {
    const pointArrayOptions = [
      [
        [this.halfWidth, this.halfHeight],
      ],
      [
        [this.quarterWidth, this.quarterHeight],
        [this.threeQuarterWidth, this.threeQuarterHeight],
      ],
      [
        [this.quarterWidth, this.quarterHeight],
        [this.threeQuarterWidth, this.quarterHeight],
        [this.halfWidth, this.threeQuarterHeight],
      ],
      [
        [this.quarterWidth, this.quarterHeight],
        [this.threeQuarterWidth, this.quarterHeight],
        [this.threeQuarterWidth, this.threeQuarterHeight],
        [this.quarterWidth, this.threeQuarterHeight],
      ],
    ];

    // For all point counts bigger than 4, render a square
    if (previewPointCount > 4) {
      return pointArrayOptions[3];
    }

    return pointArrayOptions[previewPointCount - 1];
  }

  // Render each line object defined for an ADC Field Code
  //
  // @param [Array<Object>] lineObjects the array of ADC line objects to preview
  // @param [Boolean]       closable flag to close the point-set
  //
  // @return [null]
  _renderADCLineObjects(lineObjects, closable) {
    lineObjects.forEach((lineObject) => {
      this._drawLines(lineObject, closable);
    });
  }

  // Render each text object defined for an ADC Field Code
  //
  // @param [Object] textObjects the array of ADC text objects to preview
  _renderADCTextObjects(textObjects) {
    textObjects.forEach((object) => {
      this._drawLabels(object);
    });
  }

  // Draw text labels over all points in provided array
  //
  // @param [Object] textObject the JSON object from the ADC Text Object
  _drawLabels(textObject) {
    const { offsetX, offsetY } = textObject;

    this.pointArray.forEach((point) => {
      const x = point[0] + offsetX;
      const y = point[1] + offsetY;

      this._drawText(
        textObject.content,
        x,
        y,
        textObject.color.hex_code,
      );
    });
  }

  // Render each block object defined for an ADC Field Code
  //
  // @param [Object] ctx HTML Canvas Drawing Context
  // @param [Array] point_array list of points to render in the preview
  // @param [Object] block_objects the array of ADC block objects to preview
  _renderADCBlockObjects(blockObjects) {
    blockObjects.forEach((blockObject) => {
      this._drawBlockPlaceholders(blockObject);
    });
  }

  // Draw block objects over all points in provided array
  _drawBlockPlaceholders() {
    this.pointArray.forEach((point) => {
      const [x, y] = point;
      this._drawBlockNotFound(x, y);
    });
  }

  // Draw a 'No Block in HUB' placeholder
  //
  // @param [Number] x x-axis value for point location
  // @param [Number] y y-axis value for point location
  // @param [Boolean] draw_label toggle inclusion of 'No Block in HUB' text
  _drawBlockNotFound(x, y, drawLabel = false) {
    this.context.beginPath();
    this.context.strokeStyle = '#FFFFFF';
    this.context.arc(x, y, this.width / 12, 0, 2 * Math.PI);
    this.context.stroke();

    if (drawLabel) {
      this._drawText('No Block', x - 17, y, '#FFFFFF', 8);
      this._drawText('In HUB', x - 15, y + 10, '#FFFFFF', 8);
    }
  }

  // Draw a general text object
  //
  // @param [Number] x x-axis value for point location
  // @param [Number] y y-axis value for point location
  // @param [String] color hex-value of color for point
  // @param [Number] font_size size of text
  _drawText(text, x, y, color, fontSize = 10) {
    this.context.font = `${fontSize}px Arial`;
    this.context.fillStyle = color;
    this.context.fillText(text, x, y);
  }

  // Draw all points from the points array
  //
  // @param [String] pointColorHex hex-value of color for points
  _drawPoints(pointColorHex) {
    this.pointArray.forEach((point) => {
      this._drawPoint(point[0], point[1], pointColorHex);
    });
  }

  // Draw a square object to represent a point
  //
  // @param [Number] x x-axis value for point location
  // @param [Number] y y-axis value for point location
  // @param [String] colorHex hex-value of color for point
  _drawPoint(x, y, colorHex) {
    const diameter = 4;
    const correction = diameter / 2; // Rect x, y is upper left hand corner
    const defaultPointColor = '#AB00A4';

    let fillColor = defaultPointColor;
    if (colorHex !== undefined) {
      fillColor = colorHex.hex_code;
    }

    this.context.beginPath();
    this.context.fillStyle = fillColor;
    this.context.fillRect(x - correction, y - correction, diameter, diameter);
  }

  // Draw lines connecting points in provided array
  //
  // @param [Object]  lineObject the ADC Line Object to draw lines for
  // @param [Boolean] closable open or close the perimeter of the point-set being drawn
  _drawLines(lineObject, closable) {
    const offset = parseFloat(lineObject.offset);

    if (this.pointArray.length === 2) {
      this.pointArray = this._offsetLine(offset);
    } else if (this.pointArray.length === 3) {
      this.pointArray = this._scaleTriangle(offset);
    } else if (this.pointArray.length === 4) {
      this.pointArray = this._scaleSquare(offset);
    }

    this.pointArray.forEach((point, index) => {
      const lastPoint = (index === this.pointArray.length - 1);

      let nextPoint = this.pointArray[index + 1];

      if (lastPoint) {
        if (closable) {
          [nextPoint] = this.pointArray; // Connect to the beginning
        } else {
          return;
        }
      }

      this._drawLine(
        point[0],
        point[1],
        nextPoint[0],
        nextPoint[1],
        lineObject.line_color.hex_code,
      );
    });
  }

  // Draw a line defined by two points
  //
  // @param [Number] x1 x-axis value for first point location
  // @param [Number] y1 y-axis value for first point location
  // @param [Number] x2 x-axis value for second point location
  // @param [Number] y2 y-axis value for second point location
  // @param [String] colorHex hex-value of color for point
  // @param [Number] offset amount to offset the line being drawn
  _drawLine(x1, y1, x2, y2, colorHex, offset = 0) {
    this.context.beginPath();
    this.context.strokeStyle = colorHex;
    this.context.moveTo(x1 + offset, y1 + offset);
    this.context.lineTo(x2 + offset, y2 + offset);
    this.context.stroke();
  }

  // Offset the point array by a given amount.
  //
  // @param [Number] offset scaling factor
  _offsetLine(offset) {
    const [a, b] = this.pointArray;

    const offsetX = [a[0] + offset, a[1]];
    const offsetY = [b[0] + offset, b[1]];

    return [offsetX, offsetY];
  }


  // Accept an array of the 3 points defining a triangle, and scale it up or down
  //
  // @param [Array] point_array list of points to scale up
  // @param [Number] offset scaling factor
  _scaleTriangle(offset) {
    const halfOffset = offset / 2;
    const [a, b, c] = this.pointArray;

    const scaledA = [a[0] - halfOffset, a[1] - offset];
    const scaledB = [b[0] + offset, b[1] + halfOffset];
    const scaledC = [c[0] - halfOffset, c[1] + halfOffset];

    return [scaledA, scaledB, scaledC];
  }

  // Scale a square up or down.
  //
  // @param [Number] offset scaling factor
  _scaleSquare(offset) {
    const halfOffset = offset / 2;
    const [a, b, c, d] = this.pointArray;

    const scaledA = [a[0] - halfOffset, a[1] - halfOffset];
    const scaledB = [b[0] - halfOffset, b[1] + halfOffset];
    const scaledC = [c[0] + halfOffset, c[1] + halfOffset];
    const scaledD = [d[0] + halfOffset, d[1] - halfOffset];

    return [scaledA, scaledB, scaledC, scaledD];
  }
}
