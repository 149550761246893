import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['addFormButton', 'container'];

  initialize() {
    this.addFormButtonTarget.addEventListener('click', () => {
      const currentTime = new Date().getTime();
      const regexp = new RegExp(this.addFormButtonTarget.dataset.id, 'g');

      // Replace the placeholder ID value with the current time to make each entry unique.
      const uniqueFields = this.addFormButtonTarget.dataset.fields.replace(regexp, currentTime);

      this.containerTarget.insertAdjacentHTML('beforeend', uniqueFields);
    });
  }
}
