import { Controller } from 'stimulus';

// A controller that handles the address autocomplete for field research
// @target addressInput            the autocompleted address input element
// @target placeIdInput            the input to hold the selected Google Place's ID
export default class extends Controller {
  static targets = [
    'addressInput',
    'placeIdInput',
  ];

  // Given that there is some input element for a Google Place ID
  // on the page, populate it with a provided value.
  _setPlaceIdFormValue(value) {
    if (this.hasPlaceIdInputTarget) {
      this.placeIdInputTarget.value = value;
    }
  }

  // Update the address field with the formatted address from a Google place.
  //
  // @see https://developers.google.com/maps/documentation/javascript/places#place_details
  //
  // @param [PlaceDetails] placeDetails the Google PlaceDetails object to parse.
  _updateAddressInput(placeDetails) {
    this._setPlaceIdFormValue(placeDetails.place_id);
    this.addressInputTarget.value = placeDetails.formatted_address;
    this.addressChangeByUser();
  }

  // Set the map zoom and type to the default for a selected location.
  _setMapSatelliteView() {
    this.map.setZoom(this.selectedLocationZoom);
    this.map.setMapTypeId(this.selectedLocationMapType);
  }

  // Set up Google autocomplete on the street address input.
  _setUpAddressInput() {
    if (!this.hasAddressInputTarget) {
      return;
    }

    const options = {
      fields: [
        'address_components',
        'place_id',
      ],
    };

    this.autocomplete = new google.maps.places.Autocomplete(
      this.addressInputTarget,
      options,
    );

    this.autocomplete.addListener('place_changed', () => {
      this.addressChangeByUser();
      const placeDetails = this.autocomplete.getPlace();
      this._setPlaceIdFormValue(placeDetails.place_id);
    });
  }

  // Track address changes by user separately than automated changes
  addressChangeByUser() {
    this.addressInputTarget.dataset.auto = false;
  }

  // Has user manually changed the address?
  _addressChangedByUser() {
    return !!this.addressInputTarget.dataset.auto;
  }

  // Modify the value of the address inputs by using a reverse geocoder lookup.
  //
  // @see https://developers.google.com/maps/documentation/javascript/examples/geocoding-reverse
  _updateAddressInputs() {
    if (this.hasAddressInputTarget) {
      // Only reverse geocode if the address input has no value set by the user.
      if (this.addressInputTarget.value === '' || this._addressChangedByUser()) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: this.latLng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              this._updateAddressInput(results[0]);
            }
          }
        });
      }
    }
  }

  // Set the initial controller variables, set up the address autocomplete input,
  // and set up the map view.
  initialize() {
    this._setUpAddressInput();
  }
}
