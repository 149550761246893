import { Controller } from 'stimulus';

export default class extends Controller {
  clickHandler(event) {
    const { target } = event;
    const link = target.querySelector('a');
    if (link) link.click();
  }

  initialize() {
    this.element.addEventListener('click', this.clickHandler);
  }
}
