import { Controller } from 'stimulus';

// This controller operates on a link on the page to do helpful things like disabling and setting
// it to loading while a remote request to a server is completed.
//
// @var [String] loadingClass the class to add to buttons when they are loading.
// @var [String] requestSendEvent the event that fires when a request is sent to the server.
// @var [String] requestCompleteEvent the event that always returns from the server.
export default class extends Controller {
  // Disable the controller element.
  _disableLink() {
    this.element.setAttribute('disabled', true);
    this.element.classList.add(this.loadingClass);
  }

  // Enable the controller element.
  _enableLink() {
    this.element.removeAttribute('disabled');
    this.element.classList.remove(this.loadingClass);
  }

  // Add listeners to some object that sends a request to the server.
  addListeners(listenable) {
    listenable.addEventListener(this.requestSendEvent, () => this._disableLink());
    listenable.addEventListener(this.requestCompleteEvent, () => this._enableLink());
  }

  // Define some initial variables that will be used throughout the controller,
  // and add listeners to any elements that might be sending requests to the server.
  initialize() {
    this.loadingClass = 'is-loading';
    this.requestSendEvent = 'ajax:send';
    this.requestCompleteEvent = 'ajax:complete';

    if (this.element.form !== undefined) {
      this.addListeners(this.element.form);
    } else {
      this.addListeners(this.element);
    }
  }
}
