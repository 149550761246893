import { Controller } from 'stimulus';
import DmsCoordinates from 'dms-conversion';
import parseDMS from 'parse-dms';
import debounce from 'lodash.debounce';

// A large controller that handles a map location selection form. The controller allows for location
// selection via clicking on a Google map, inputting an address, inputting a decimal latitude or
// longitude, or inputting a Degrees/Minutes/Seconds-formatted latitude or longitude.
//
// @target addressInput            the autocompleted address input element
// @target countyInput             the county from the autocompleted address
// @target latitudeDecimalInput    the decimal latitude input
// @target latitudeDegreesInput    the DMS latitude degrees input
// @target latitudeMinutesInput    the DMS latitude minutes input
// @target latitudeSecondsInput    the DMS latitude seconds input
// @target latitudeDirectionInput  the DMS latitude direction input
// @target longitudeDecimalInput   the decimal longitude input
// @target longitudeDegreesInput   the DMS longitude degrees input
// @target longitudeMinutesInput   the DMS longitude minutes input
// @target longitudeSecondsInput   the DMS longitude seconds input
// @target longitudeDirectionInput the DMS longitude direction input
// @target map                     the container for the Google map
// @target placeIdInput            the input to hold the selected Google Place's ID
export default class extends Controller {
  static targets = [
    'addressInput',
    'countyInput',
    'latitudeDecimalInput',
    'latitudeDegreesInput',
    'latitudeMinutesInput',
    'latitudeSecondsInput',
    'latitudeDirectionInput',
    'longitudeDecimalInput',
    'longitudeDegreesInput',
    'longitudeMinutesInput',
    'longitudeSecondsInput',
    'longitudeDirectionInput',
    'map',
    'placeIdInput',
  ];

  // Add the initial map to the page.
  _setUpMap() {
    if (!this.hasMapTarget) {
      return;
    }

    const options = {
      zoom: this.startingMapZoom,
      center: this.latLng,
      mapTypeId: this.startingMapType,
      streetViewControl: false,
    };

    this.map = new google.maps.Map(this.mapTarget, options);

    this.map.addListener('click', (event) => {
      this.latLng = event.latLng;
      this._setMapSatelliteView();
      this._updateSelectedLocation();
      this._updateLatitudeLongitudeDecimalInputs();
      this._updateLatitudeLongitudeDmsInputs();
    });
  }


  // Given that there is some input element for a Google Place ID
  // on the page, populate it with a provided value.
  _setPlaceIdFormValue(value) {
    if (this.hasPlaceIdInputTarget) {
      this.placeIdInputTarget.value = value;
    }
  }

  // Update the address field with the formatted address from a Google place.
  //
  // @see https://developers.google.com/maps/documentation/javascript/places#place_details
  //
  // @param [PlaceDetails] placeDetails the Google PlaceDetails object to parse.
  _updateAddressInput(placeDetails) {
    this._setPlaceIdFormValue(placeDetails.place_id);
    this.addressInputTarget.value = placeDetails.formatted_address;
    this.addressChangeByUser();
    const county = placeDetails.address_components.find(component => component.types[0] === 'administrative_area_level_2');
    if (county) this.countyInputTarget.value = county.long_name;
  }

  // Set the map zoom and type to the default for a selected location.
  _setMapSatelliteView() {
    this.map.setZoom(this.selectedLocationZoom);
    this.map.setMapTypeId(this.selectedLocationMapType);
  }

  // Set up Google autocomplete on the street address input.
  _setUpAddressInput() {
    if (!this.hasAddressInputTarget) {
      return;
    }

    const options = {
      fields: [
        'geometry.location',
        'address_components',
        'place_id',
      ],
    };

    this.autocomplete = new google.maps.places.Autocomplete(
      this.addressInputTarget,
      options,
    );

    this.autocomplete.addListener('place_changed', () => {
      this.addressChangeByUser();
      const placeDetails = this.autocomplete.getPlace();
      this._setPlaceIdFormValue(placeDetails.place_id);

      this.countyInputTarget.value = placeDetails.address_components.find(component => component.types[0] === 'administrative_area_level_2').long_name;

      // Don't overwrite other form data if it's already present.
      if (this._latitudeLongitudePopulated()) {
        return;
      }

      this._setMapSatelliteView();

      this.latLng = new google.maps.LatLng(
        placeDetails.geometry.location.lat(),
        placeDetails.geometry.location.lng(),
      );

      this._updateMapMarker();
      this._updateMapCenter();
      this._updateLatitudeLongitudeDecimalInputs();
      this._updateLatitudeLongitudeDmsInputs();
    });
  }

  // Given some DMS values, output the decimal degrees.
  //
  // @see https://stackoverflow.com/a/1140335/2901457
  //
  // @param [Integer] degrees   the number of degrees
  // @param [Integer] minutes   the number of minutes
  // @param [Float]   seconds   the number of seconds
  // @param [String]  direction the DMS cardinal direction
  //
  // @return [Object] an object with Floating point keys lat and lon
  _dmsToDecimal(degrees, minutes, seconds, direction) {
    try {
      return parseDMS(`${direction}${degrees}° ${minutes}' ${seconds}"`);
    } catch (e) {
      return { lat: 0, lon: 0 };
    }
  }

  // Refresh the map marker, center, and all address inputs.
  _updateSelectedLocation() {
    this._updateMapMarker();
    this._updateMapCenter();
    this._updateAddressInputs();
  }

  // Track address changes by user separately than automated changes
  addressChangeByUser() {
    this.addressInputTarget.dataset.auto = false;
  }

  // Has user manually changed the address?
  _addressChangedByUser() {
    return !!this.addressInputTarget.dataset.auto;
  }

  // Modify the value of the address inputs by using a reverse geocoder lookup.
  //
  // @see https://developers.google.com/maps/documentation/javascript/examples/geocoding-reverse
  _updateAddressInputs() {
    if (this.hasAddressInputTarget) {
      // Only reverse geocode if the address input has no value set by the user.
      if (this.addressInputTarget.value === '' || this._addressChangedByUser()) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: this.latLng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              this._updateAddressInput(results[0]);
            }
          }
        });
      }
    }
  }

  // Check whethere or not all DMS inputs for latitude and longitude are
  // present.
  //
  // @return [Boolean] true if all inputs are present, otherwise false
  _latitudeLongitudeDmsInputsPresent() {
    return this.hasLatitudeDegreesInputTarget
      && this.hasLatitudeMinutesInputTarget
      && this.hasLatitudeSecondsInputTarget
      && this.hasLatitudeDirectionInputTarget
      && this.hasLongitudeDegreesInputTarget
      && this.hasLongitudeMinutesInputTarget
      && this.hasLongitudeSecondsInputTarget
      && this.hasLongitudeDirectionInputTarget;
  }

  _latitudeLongitudePopulated() {
    return this.hasLatitudeDecimalInputTarget
      && !!this.latitudeDecimalInputTarget.value
      && this.hasLongitudeDecimalInputTarget
      && !!this.longitudeDecimalInputTarget.value;
  }

  // Update the decimal latitude and longitude input values from the
  // controller latitude and longitude.
  _updateLatitudeLongitudeDecimalInputs() {
    if (this.hasLatitudeDecimalInputTarget) {
      this.latitudeDecimalInputTarget.value = this.latLng.lat();
    }

    if (this.hasLongitudeDecimalInputTarget) {
      this.longitudeDecimalInputTarget.value = this.latLng.lng();
    }
  }

  // Update the DMS latitude and longitude DMS inputs by converting the controller's decimal
  // latitude and longitude components to their various DMS components.
  //
  // @see https://github.com/WSDOT-GIS/dms-js
  _updateLatitudeLongitudeDmsInputs() {
    if (!this._latitudeLongitudeDmsInputsPresent) {
      return;
    }

    const converter = new DmsCoordinates(this.latLng.lat(), this.latLng.lng());
    const { latitude, longitude } = converter.getDmsArrays();
    const [latDegrees, latMinutes, latSeconds, latDirection] = latitude;
    const [lonDegrees, lonMinutes, lonSeconds, lonDirection] = longitude;

    this.latitudeDegreesInputTarget.value = latDegrees;
    this.latitudeMinutesInputTarget.value = latMinutes;
    this.latitudeSecondsInputTarget.value = latSeconds;
    this.latitudeDirectionInputTarget.value = latDirection;

    this.longitudeDegreesInputTarget.value = lonDegrees;
    this.longitudeMinutesInputTarget.value = lonMinutes;
    this.longitudeSecondsInputTarget.value = lonSeconds;
    this.longitudeDirectionInputTarget.value = lonDirection;
  }

  // Add a new map marker to the map from the controller's latitude and longitude values.
  _initializeMapMarker() {
    this.selectedLocationMarker = new google.maps.Marker({
      position: this.latLng,
      draggable: true,
      title: 'Selected Job Site Location',
    });

    this.selectedLocationMarker.setMap(this.map);

    this.selectedLocationMarker.addListener('drag', (event) => {
      this.latLng = event.latLng;
      this._updateLatitudeLongitudeDecimalInputs();
      this._updateLatitudeLongitudeDmsInputs();
    });

    this.selectedLocationMarker.addListener('dragend', (event) => {
      this.latLng = event.latLng;
      this._updateSelectedLocation();
      this._updateLatitudeLongitudeDecimalInputs();
      this._updateLatitudeLongitudeDmsInputs();
    });
  }

  // Move the map marker if it exists, or initialize a new one if it does not.
  _updateMapMarker() {
    if (this.selectedLocationMarker !== undefined) {
      this.selectedLocationMarker.setPosition(this.latLng);
    } else {
      this._initializeMapMarker();
    }
  }

  // Set the map center to the controller latitude and longitude values.
  _updateMapCenter() {
    if (!this.hasMapTarget) {
      return;
    }

    this.map.panTo(this.latLng);
  }

  // Retrieve a new controller latitude and longitude value from the form
  // decimal latitude and longitude inputs, then sync up the rest of the page
  // to reflect the same values.
  _updateLocationFromDecimalInput() {
    if (!this._latitudeLongitudePopulated()) return;

    this.latLng = new google.maps.LatLng(
      this.latitudeDecimalInputTarget.value,
      this.longitudeDecimalInputTarget.value,
    );

    this._updateLatitudeLongitudeDmsInputs();
    this._setMapSatelliteView();
    this._updateSelectedLocation();
  }

  // Debounced wrapper for updating the map location when a decimal input
  // is changed.
  updateLocationFromDecimalInput = debounce(
    this._updateLocationFromDecimalInput,
    500,
    { maxWait: 3000 },
  );

  // Retrieve a new controller latitude value from the form DMS latitude input,
  // then sync up the rest of the page to reflect the same values.
  _updateLatitudeFromDmsInput() {
    const latitude = this._dmsToDecimal(
      this.latitudeDegreesInputTarget.value,
      this.latitudeMinutesInputTarget.value,
      this.latitudeSecondsInputTarget.value,
      this.latitudeDirectionInputTarget.value,
    ).lat;

    this.latLng = new google.maps.LatLng(latitude, this.latLng.lng());

    this._updateLatitudeLongitudeDecimalInputs();
    this._setMapSatelliteView();
    this._updateSelectedLocation();
  }

  // Debounced wrapper for updating the map latitude when a DMS input
  // is changed.
  updateLatitudeFromDmsInput = debounce(
    this._updateLatitudeFromDmsInput,
    500,
    { maxWait: 3000 },
  );

  // Retrieve a new controller longitude value from the form DMS longitude input,
  // then sync up the rest of the page to reflect the same values.
  _updateLongitudeFromDmsInput() {
    const longitude = this._dmsToDecimal(
      this.longitudeDegreesInputTarget.value,
      this.longitudeMinutesInputTarget.value,
      this.longitudeSecondsInputTarget.value,
      this.longitudeDirectionInputTarget.value,
    ).lon;

    this.latLng = new google.maps.LatLng(this.latLng.lat(), longitude);

    this._updateLatitudeLongitudeDecimalInputs();
    this._setMapSatelliteView();
    this._updateSelectedLocation();
  }

  // Debounced wrapper for updating the map longitude when a DMS input
  // is changed.
  updateLongitudeFromDmsInput = debounce(
    this._updateLongitudeFromDmsInput,
    500,
    { maxWait: 3000 },
  );

  // Two zoom levels are defined:
  // - 5, which is quite far out, for the initial map view
  // - 18, which is quite close, for a selected location
  //
  // Two map types are defined:
  // - ROADMAP, for the initial map view
  // - SATELLITE, for a selected location
  //
  // The initial latitude/longitude is defined:
  // - Using the current form values, if they are non-zero
  // - Using Atlanta's location if no form values are set
  _setInitialControllerVariables() {
    this.startingMapZoom = 5;
    this.startingMapType = google.maps.MapTypeId.ROADMAP;

    this.selectedLocationZoom = 18;
    this.selectedLocationMapType = google.maps.MapTypeId.SATELLITE;

    const formLat = parseFloat(this.latitudeDecimalInputTarget.value);
    const formLong = parseFloat(this.longitudeDecimalInputTarget.value);

    if (formLat === 0 && formLong === 0) {
      const atlanta = { lat: 33.753746, lng: -84.386330 };
      this.latLng = new google.maps.LatLng(atlanta.lat, atlanta.lng);
    } else {
      this.latLng = new google.maps.LatLng(formLat, formLong);
      this.startingMapZoom = this.selectedLocationZoom;
      this.startingMapType = this.selectedLocationMapType;
    }
  }

  // Set the initial controller variables, set up the address autocomplete input,
  // and set up the map view.
  initialize() {
    this._setInitialControllerVariables();
    this._setUpAddressInput();
    this._setUpMap();
    this._initializeMapMarker();
  }
}
