import { Controller } from 'stimulus';

// This controller adds the ability to remove any arbitrary object in the DOM.
//
// @var [String] loadingClass the class to add to buttons when they are loading.
// @var [String] requestSendEvent the event that fires when a request is sent to the server.
// @var [String] requestSuccessEvent the event that returns after success on the server.
// @var [String] requestCompleteEvent the event that always returns from the server.
//
// @target [Element] disableable disable these elements when a requester submits.
// @target [Element] requester listen for server submissions from these elements.
//
// @data [String] animation which animation from Animate.css should be used to remove the element.
//
// @example A notification with a close button:
//   <div data-controller="removable" class="notification">
//     <p>Notification content is here</p>
//     <button data-action="click->removable#remove">X</button>
//   </div>
//
// @example An element on a page that can be deleted from the server with a button through AJAX:
//   <div data-controller="removable">
//     <p>Object name</p>
//     <p>Object created at</p>
//     <a href="/delete_object" data-remote="true" data-target="removable#requester">Remove Me</a>
//   </div>
//
// @example A form with a submit button that should be removed after the form request is complete.
//   <div data-controller="removable">
//     <form data-remote="true" data-target="removable.requester">
//       <input name="object_name" value="Object name">
//       <input name="object_description" value="Object description">
//       <button type="submit" data-target="removable.disableable">Submit Form</button>
//     </form>
//   </div>
export default class extends Controller {
  static targets = ['disableable', 'requester'];

  // Disable all disableable targets
  disableButton() {
    this.disableableTargets.forEach((element) => {
      element.setAttribute('disabled', true);
      element.classList.add(this.loadingClass);
    });
  }

  // Enable any previously disabled disableable targets.
  enableButton() {
    this.disableableTargets.forEach((element) => {
      element.removeAttribute('disabled');
      element.classList.remove(this.loadingClass);
    });
  }

  // Remove the element that defined the controller.
  //
  // This function allows for the use of Animate.css animations to control removal.
  //
  // We add a timeout to ensure that if this function is called first of many actions,
  // the other actions on the same element will have a chance to execute.
  remove() {
    if (this.data.has('animation')) {
      this.element.classList.add('animated');
      this.element.classList.add(this.data.get('animation'));

      // Remove the element after the animation has finished.
      this.element.addEventListener(window.animationEndEvent, () => {
        this._removeElement(this.element);
      });
    } else {
      setTimeout(() => {
        this._removeElement(this.element);
      }, 1);
    }
  }

  _removeElement(element) {
    const parentElement = element.parentNode;

    if (parentElement !== null) {
      parentElement.removeChild(element);
    }
  }

  // Add listeners to some object that sends a request to the server.
  addListeners(listenable) {
    listenable.addEventListener(this.requestSendEvent, () =>
      this.disableButton());
    listenable.addEventListener(this.requestSuccessEvent, () => this.remove());
    listenable.addEventListener(this.requestCompleteEvent, () =>
      this.enableButton());
  }

  // Define some initial variables that will be used throughout the controller,
  // and add listeners to any elements that might be sending requests to the server.
  initialize() {
    this.loadingClass = 'is-loading';
    this.requestSendEvent = 'ajax:send';
    this.requestSuccessEvent = 'ajax:success';
    this.requestCompleteEvent = 'ajax:complete';
    this.requesterTargets.forEach(target => this.addListeners(target));
  }
}
