import { Controller } from 'stimulus';

// This controller adds functionality to a file input to allow a nicer file preview format.
//
// @target fileList the list container for the files to be listed within.
// @target input    the file input to watch for uploaded files on.
//
// @data   useTag   included when each file output is desired to be a Bulma tag.
export default class extends Controller {
  static targets = ['fileList', 'input'];

  // Given some file input element, add previews to the page for each file in the input.
  _appendFilePreviews(fileInputElement) {
    Array.from(fileInputElement.files).forEach((file) => {
      let insertedHTML = file.name;

      // Display as a tag if the UI requests it.
      if (this.data.get('useTag') !== null) {
        insertedHTML = `<span class="tag is-info">${file.name}</span>`;
      }

      // Append the file to a list if requested, otherwise just set the content.
      if (this.inputTarget.multiple) {
        insertedHTML = `<li>${insertedHTML}</li>`;
        this.fileListTarget.insertAdjacentHTML('afterbegin', insertedHTML);
      } else {
        this.fileListTarget.innerHTML = insertedHTML;
      }
    });
  }

  // Listen to the file input target and append preview elements whenever files
  // are added.
  initialize() {
    this.inputTarget.addEventListener('change', (event) => {
      this._appendFilePreviews(event.currentTarget);
    });
  }
}
