import { Controller } from 'stimulus';

// This controller allows for various debounced actions to be taken
// on DOM elements.
export default class extends Controller {
  static targets = [];

  // Do not manually call this from an action
  protectButtonMash(event) {
    event.target.classList.add('is-loading');
    event.target.classList.add('disabled');
  }
}
