import { Controller } from 'stimulus';

// Add some base functionality to Bulma modals.
export default class extends Controller {
  static targets = ['animated'];

  // Release the background so that it is scrollable again.
  // Set the scroll position back to wherever it was when the body was initially locked.
  _unlockDocumentBody() {
    document.querySelector('html').classList.remove('is-clipped');
  }

  // Ensure that the background cannot be scrolled while a modal is open.
  // Store the scroll position so that it can be returned to that position on close.
  _lockDocumentBody() {
    document.querySelector('html').classList.add('is-clipped');
  }

  // Close the modal
  close() {
    window.flashMessageContainer.innerHTML = '';
    this.element.classList.remove('is-active');
  }

  // Set up an observer when the component is initialized. We watch for classList
  // changes so that we know when the modal is actually being displayed.
  initialize() {
    this.observer = new MutationObserver(() => {
      if (this.element.classList.contains('is-active')) {
        this._lockDocumentBody();
      } else {
        this._unlockDocumentBody();
      }
    });

    this.observer.observe(this.element, {
      attributes: true,
      attributeFilter: ['class'],
    });

    if (this.element.classList.contains('is-active')) {
      this._lockDocumentBody();
    }
  }

  // Tear down the observer when the controller is destroyed.
  disconnect() {
    this._unlockDocumentBody();
    this.observer.disconnect();
  }
}
