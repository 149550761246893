import { Controller } from 'stimulus';

// Add fuctionality to the list of Site Questions to facilitate additions.
//
// @target [Element] form the form to listen to for submission events.
// @target [Element] input the element that new Site Questions are entered in.
// @target [Element] submitButton the element that submits the Site Question form.
export default class extends Controller {
  static targets = ['form', 'input', 'submitButton'];

  _disableFormElements() {
    this.submitButtonTarget.setAttribute('disabled', true);
    this.inputTarget.setAttribute('disabled', true);
    this.submitButtonTarget.classList.add(this.loadingClass);
  }

  _enableFormElements() {
    this.inputTarget.removeAttribute('disabled');
    this.submitButtonTarget.removeAttribute('disabled');
    this.submitButtonTarget.classList.remove(this.loadingClass);
  }

  _resetFormElements() {
    this.inputTarget.value = '';
    this.inputTarget.placeholder = this.data.get('placeholder');
  }

  // Handle all of the Rails Ajax events so that we can update the UI and
  // make everything feel smooth.
  _addSubmitEventHandlers() {
    this.formTarget.addEventListener(this.requestSendEvent, () => {
      this._disableFormElements();
    });

    this.formTarget.addEventListener(this.requestSuccessEvent, () => {
      this._resetFormElements();
    });

    this.formTarget.addEventListener(this.requestCompleteEvent, () => {
      this._enableFormElements();
    });
  }

  initialize() {
    this.requestSendEvent = 'ajax:send';
    this.requestSuccessEvent = 'ajax:success';
    this.requestCompleteEvent = 'ajax:complete';
    this.loadingClass = 'is-loading';
    this._addSubmitEventHandlers();
  }
}
