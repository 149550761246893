import { Controller } from 'stimulus';
import xor from 'lodash.xor';

// Extend the functionality of the Point Data File show page by adding
// this controller to the filter form.  For each filter button in the filter
// form, call the `toggleFilter` action to toggle that filter's status.
export default class extends Controller {
  static targets = ['field'];

  // Given some status, use Lodash's xor function to toggle that status in the hidden field value.
  //
  // @see https://lodash.com/docs#xor
  //
  // @param [String] status the status to toggle in the hidden form field.
  _toggleFieldStatus(status) {
    const currentStatuses = this.fieldTarget.value.split(',');

    if (currentStatuses.length === 1 && currentStatuses[0] === '') {
      this.fieldTarget.value = status;
      return;
    }

    this.fieldTarget.value = xor(currentStatuses, [status]).join(',');
  }

  // Use Rails helpers to submit the form to the server.
  _submitForm() {
    Rails.fire(this.element, 'submit');
  }

  // Whenever a button calls this event, toggle the appearance so that it looks like filters are
  // being toggled. Also toggle that filter button's value in the hidden form field.
  //
  // @param [Event] event the event that triggered the function call.
  //
  // @data [String] status the status to toggle in the hidden form field.
  // @data [String] colorClass the class to toggle in the button filter's CSS.
  toggleFilter(event) {
    event.currentTarget.classList.toggle('is-outlined');
    event.currentTarget.classList.toggle(`${event.currentTarget.dataset.colorClass}`);

    this._toggleFieldStatus(event.currentTarget.dataset.status);

    this._submitForm();
  }
}
