import { Controller } from 'stimulus';

// This controller exists to provide specific funtionality to the client contact form:
// - Update submit confirmation messages based on the appropriate form checkbox.
//
// @target [Element] actionContainer       the element that contains the form submission button.
// @target [Element] sendWelcomeEmailInput the element that controls welcome email sending.
//
// @data [String] sendWelcomeEmailConfirm the message when an email is sent on contact creation.
// @data [String] noWelcomeEmailConfirm   the message when no email is sent on contact creation.
export default class extends Controller {
  static targets = ['actionContainer', 'sendWelcomeEmailInput'];

  // Check the value of the welcome email input target, and update the confirmation message
  // on the form submit button appropriately.
  _updateSubmitConfirmMessage() {
    const submitButton = this.actionContainerTarget.querySelector('.button.is-success');

    if (submitButton !== null) {
      let confirmMessage = null;

      if (this.sendWelcomeEmailInputTarget.checked) {
        confirmMessage = this.data.get('sendWelcomeEmailConfirm');
      } else {
        confirmMessage = this.data.get('noWelcomeEmailConfirm');
      }

      if (confirmMessage !== null) {
        submitButton.dataset.confirm = confirmMessage;
      }
    }
  }

  // Listen to the welcome email sending checkbox so that we can update the confirmation message
  // appropriately.
  _addWelcomeEmailListener() {
    this.sendWelcomeEmailInputTarget.addEventListener('input', () => {
      this._updateSubmitConfirmMessage();
    });
  }

  // Add listeners to elements on the form, and set the initial state.
  initialize() {
    this._addWelcomeEmailListener();
    this._updateSubmitConfirmMessage();
  }
}
