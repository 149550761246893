import { Controller } from 'stimulus';

// Given some field, field units and a scale value, this controller calculates a second computed
// field value based on the field, units and scale value. The computed value can be toggled on
// and off with a checkbox.
//
//  @target [Element]   parameterOne        input for P1 value entered by user
//  @target [Element]   parameterTwo        input for P2 value computed by Hub
//  @target [Element]   parameterOneUnits   select input for intended units of P1
//  @target [Element]   scaleFactor         input for scaling factor for P2 result
//  @target [Element]   parameterTwoWrapper div wrapping P2 value, scale factor and description
//  @target [Element]   toggleCheckbox      checkbox input for enabling usage of P2
export default class extends Controller {
  static targets = [
    'parameterOne',
    'parameterTwo',
    'parameterOneUnits',
    'scaleFactor',
    'parameterTwoWrapper',
    'toggleCheckbox',
  ]

  // Retrieve the select-option value from the units select input field
  _getUnitsText(element) {
    return element.options[element.selectedIndex].text;
  }

  // Use P1, P1 units, and scale factor to compute the P2 value
  computeParameterTwo() {
    let computedValue = null;

    const scaleFactor = this.scaleFactorTarget.value;
    const parameterOne = this.parameterOneTarget.value;
    const parameterOneUnits = this._getUnitsText(this.parameterOneUnitsTarget);

    computedValue = scaleFactor * parameterOne;

    if (parameterOneUnits === 'Inches') {
      // Terramodel draws everything in feet by default.
      // Two draw in inches, you must divide a parameter by 12.
      computedValue /= 12;
    }

    // Only update the computed value if P2 is enabled
    if (this.toggleCheckboxTarget.checked) {
      this.parameterTwoTarget.value = computedValue;
    }
  }

  // Toggle the display for the P2 input fields
  toggleParameterTwoFields() {
    if (this.toggleCheckboxTarget.checked) {
      this._showParameterTwoWrapper();
    } else {
      this._hideParameterTwoWrapper();
    }
  }

  // Display input fields for P2
  _showParameterTwoWrapper() {
    this.parameterTwoWrapperTarget.classList.remove('is-hidden');
  }

  // Hide input fields for P2
  _hideParameterTwoWrapper() {
    this.parameterTwoWrapperTarget.classList.add('is-hidden');
    this.parameterTwoTarget.value = '';
    this.scaleFactorTarget.value = '';
  }

  // Compute the P2 value when the form is loaded
  initialize() {
    this.computeParameterTwo();
  }
}
