import { Controller } from 'stimulus';

// This controller adds functionality to a phone number input so that it can be formatted
// in a standard way.
export default class extends Controller {
  static targets = [];

  // Given a string, format it like a phone number.
  //
  // @param [String] phoneNumber a string that is composed of up to ten digits.
  //
  // @return [String] a string that is a formatted phone number.
  formatAsPhone(phoneNumber) {
    const firstThree = phoneNumber.substring(0, 3);
    const secondThree = phoneNumber.substring(3, 6);
    const lastFour = phoneNumber.substring(6, 10);

    switch (phoneNumber.length) {
      case 4:
      case 5:
      case 6:
        return `${firstThree}-${secondThree}`;
      case 7:
      case 8:
      case 9:
      case 10:
        return `${firstThree}-${secondThree}-${lastFour}`;
      default:
        return phoneNumber;
    }
  }

  // Process an input event on the telephone input to
  // transform it into a phone number format.
  handleInput(event) {
    let phoneNumberInput = event.currentTarget.value;

    // Strip out non-digit characters.
    phoneNumberInput = phoneNumberInput.replace(/\D/g, '');

    // Ensure we're working with a max of ten characters.
    phoneNumberInput = phoneNumberInput.substring(0, 10);

    // Format the phone number to have dashes between the segments.
    phoneNumberInput = this.formatAsPhone(phoneNumberInput);

    // Set the input value to the transformed value.
    event.currentTarget.value = phoneNumberInput;
  }
}
