import { Controller } from 'stimulus';

// This controller adds all necessary JS functionality to a Bulma navbar.
//
// @see https://bulma.io/documentation/components/navbar/#navbarJsExample
export default class extends Controller {
  static targets = [];

  toggleMobileMenu() {
    this.activatables.forEach((selector) => {
      this.element.querySelector(selector).classList.toggle(this.activeClass);
    });
  }

  initialize() {
    this.activatables = ['.navbar-burger', '.navbar-menu'];
    this.activeClass = 'is-active';
  }
}
