import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['element'];

  initialize() {
    this.featureName = this.elementTarget.dataset.feature;
    this.userId = this.elementTarget.dataset.userId;
  }

  track(_) {
    const data = {
      feature_usage: {
        name: this.featureName,
        event: 'click',
        user_id: this.userId,
      },
    };

    this._sendTrackingData(data);
    window.ga('send', 'event', 'Feature Used', this.featureName);
  }

  _sendTrackingData(data) {
    Rails.ajax({
      url: '/feature_usages',
      type: 'post',
      dataType: 'json',
      data,
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        options.data = JSON.stringify(data);
        return true;
      },
      success: () => {
        // Do Nothing
      },
    });
  }
}
