import { Controller } from 'stimulus';

// This controller adds functionality to a file-only form that is processed over AJAX.
//
// @data [String] nextFileText the text to show when waiting for an additional file.
// @data [String] fileNamePlaceholder the text to show if no file was uploaded.
export default class extends Controller {
  static targets = ['fileField', 'fileSelectionPrompt', 'fileName', 'submitButton', 'jobSelect'];

  // Whenever a file is added:
  // - Update the name on the file field
  fileAdded() {
    this.fileNameTarget.textContent = this.fileFieldTarget.files[0].name;

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.removeAttribute('disabled');
    }

    this.fileFieldTarget.form.addEventListener('ajax:complete', () => {
      this.fileFieldTarget.value = null;
      this.fileSelectionPromptTarget.textContent = this.data.get('nextFileText');
    });

    this.fileFieldTarget.form.addEventListener('ajax:error', () => {
      this.fileNameTarget.textContent = this.data.get('fileNamePlaceholder');
    });
  }

  // Fetch Jobs from the server
  _refreshJobs(identifier) {
    Rails.ajax({
      url: '/jobs.json',
      type: 'get',
      dataType: 'json',
      data: new URLSearchParams({
        for_asc_files_filter: true,
        q: identifier,
        limit: 5,
      }).toString(),
      success: (response) => {
        if (response.length !== 0) {
          this.jobChoicesObject.setChoices(
            response.map(object => ({ label: object.identifier, value: object.id.toString() })),
            'value',
            'label',
            true,
          );

          this.jobChoicesObject.enable();
        }
      },
    });
  }

  initialize() {
    // wait for nested choices select controller to initialize
    setTimeout(() => {
      if (this.jobSelectTarget.value !== '') {
        this._refreshJobs(this.jobSelectTarget.value);
      }
    }, 1);

    // add AJAX autocomplete functionality to the choices element
    this.jobSelectTarget.addEventListener('search', (event) => {
      const text = event.detail.value;

      if (text.length >= 3) {
        this._refreshJobs(text);
      }
    });
  }

  // Retrieve the Company Choices controller that is available on this element, and from
  // that controller, retrieve the Choices object.
  get jobChoicesObject() {
    return this.application.getControllerForElementAndIdentifier(
      this.jobSelectTarget,
      'choices--select',
    ).choicesObject;
  }
}
